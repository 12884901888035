import ApiGateway, { CustomAxiosResponse } from '@app/helpers/AxiosHelper';

const { REACT_APP_LEAD_URL } = process.env;

export interface TcheckData {
    created: string;
    vehicleId: string;
    tchekId: string;
    isDetectionFinished: boolean;
    isReportCreated: boolean;
    updated: string;
    reminderTokenId: string;
    nbReminds: number;
    lastRemind: string;
    tradeInStatus: number | null;
    tradeInStatusUpdatedAt: string;
}

export interface TchekUser {
    id: string;
    email: string;
    isAdmin: boolean;
    username: string;
}

export function getTcheks(id: number) {
    return ApiGateway.get<TcheckData[], CustomAxiosResponse<TcheckData[]>>(`${REACT_APP_LEAD_URL}/tchek/${id}`);
}

export function createTchek(id: number) {
    return ApiGateway.post<TcheckData[], CustomAxiosResponse<TcheckData[]>>(`${REACT_APP_LEAD_URL}/tchek/${id}`);
}

export function remindTchek(id: number) {
    return ApiGateway.post<TcheckData[], CustomAxiosResponse<TcheckData[]>>(`${REACT_APP_LEAD_URL}/tchek/${id}/remind`);
}

export function getTchekUsers() {
    return ApiGateway.get<TchekUser[], CustomAxiosResponse<TchekUser[]>>(
        `${REACT_APP_LEAD_URL}/tchek/external-users-ids`,
    );
}
