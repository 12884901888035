import { DevTool } from '@hookform/devtools';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, withRouter } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { routeTo } from '@app/helpers/RoutesHelper';
import { empty } from '@app/helpers/ToolsHelper';

import { getActions } from '@app/crud/apv/mecaplanning.crud';
import { getPackages } from '@app/crud/apv/package.crud';

import ROUTES from '@app/router/Routes';
import { fetchPackage, removePackage } from '@app/store/apv/package/package.thunk';

import Editor from '@app/partials/content/Editor';
import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';
import PictureUpload from '@app/partials/content/PictureUpload';
import SwitchInput from '@app/partials/content/SwitchInput';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { formSubmit } from './Form/Form.submit';
import MakeSegments from './MakeSegment';

const defaultValues = {
    packageCategoryId: -1,
    name: '',
    code: '',
    active: false,
    seoTitle: '',
    seoUrl: '',
    seoH1: '',
    seoMetaDescription: '',
    seoIntroduction: '',
    seoDescription: '',
    prices: [],
    pathVideo: '',
    pathImg: '',
    promote: false,
    packageParentId: -1,
    isDegressivity: false,
    mo: '',
    tmo: '',
    mecaplanningActionId: -1,
};

function Package({ history }) {
    const { reset, setValue, register, errors, handleSubmit, control } = useFormContext();
    const params = useParams();
    const dispatch = useDispatch();

    const newPackage = typeof params.id === 'undefined';
    const packageSelected = useSelector((state) => state.package.package);
    const { categories, segments, error, loading } = useSelector((state) => state.package);
    const [isOption, setIsOption] = useState(false);
    const {
        fetch: fetchParentPackages,
        data: parentPackages,
        setData: setParentPackages,
        loading: loadingParentPackage,
    } = useFetch({
        fetchAction: getPackages,
        resultInterceptor: (response) => response?.result?.packages?.filter?.((p) => !p?.packageParent) || [],
    });

    const { data: mecaplanningActions } = useFetch({
        fetchAction: getActions,
        autoFetch: true,
    });

    const onSubmit = (data) => {
        formSubmit({
            newPackage,
            data,
            dispatch,
            history,
            id: params.id,
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const deletePackage = () => {
        dispatch(
            removePackage({
                id: params.id,
                history,
            }),
        );
    };

    useEffect(() => {
        dispatch(fetchPackage(params.id));

        return () => {
            reset(defaultValues);
        };
    }, [dispatch, reset, params.id]);

    const generatePrices = useCallback(() => {
        const defaultPrices = [...defaultValues.prices];

        if (segments?.bodyTypeSegment) {
            segments.makeSegment.forEach((makeSegment) => {
                segments.packagePriceZone.forEach((priceZone) => {
                    segments.bodyTypeSegment.forEach((bodyType) => {
                        defaultPrices.push({
                            makeSegment: makeSegment.id,
                            priceZone: priceZone.id,
                            bodyType: bodyType.id,
                            code: '',
                            price: undefined,
                            active: true,
                        });
                    });
                });
            });
        }

        return defaultPrices;
    }, [segments]);

    const handleOptionChange = (value) => {
        if (value && !isOption) {
            fetchParentPackages({
                items: 10000,
            });
        } else if (!isOption) {
            setParentPackages([]);
        }

        setIsOption(value);
    };

    const setFormValues = useCallback(() => {
        setValue('packageCategoryId', packageSelected?.packageCategory?.id || defaultValues.packageCategoryId);
        setValue('packageParentId', packageSelected?.packageParent?.id || defaultValues.packageParentId);
        setValue('mecaplanningActionId', packageSelected?.mecaplanningAction?.id || defaultValues.mecaplanningActionId);
        setValue('name', packageSelected?.name || defaultValues.name);
        setValue('code', packageSelected?.code || defaultValues.code);
        setValue('active', packageSelected?.active || defaultValues.active);
        setValue('promote', packageSelected?.promote || defaultValues.promote);
        setValue('seoTitle', packageSelected?.seoTitle || defaultValues.seoTitle);
        setValue('seoUrl', packageSelected?.seoUrl || defaultValues.seoUrl);
        setValue('seoH1', packageSelected?.seoH1 || defaultValues.seoH1);
        setValue('seoMetaDescription', packageSelected?.seoMetaDescription || defaultValues.seoMetaDescription);
        setValue('seoIntroduction', packageSelected?.seoIntroduction || defaultValues.seoIntroduction);
        setValue('seoDescription', packageSelected?.seoDescription || defaultValues.seoDescription);
        setValue('pathVideo', packageSelected?.pathVideo || defaultValues.pathVideo);
        setValue('pathImg', packageSelected?.pathImg || defaultValues.pathImg);
        setValue('isDegressivity', packageSelected?.isDegressivity || defaultValues.isDegressivity);
        setValue('mo', packageSelected?.mo || defaultValues.mo);
        setValue('tmo', packageSelected?.tmo || defaultValues.tmo);
        setValue('rank', packageSelected?.rank || '');
        setValue('prices', packageSelected?.prices || generatePrices());
    }, [generatePrices, packageSelected, segments, setValue]);

    useEffect(() => {
        setFormValues();

        if (packageSelected?.packageParent?.id) {
            handleOptionChange(true);
        }
    }, [setFormValues]);

    useEffect(() => {
        if (error !== null) {
            toast({
                onShow: () => history.push(routeTo(ROUTES.PACKAGES.PATH.ALL)),
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    }, [history, error]);

    useEffect(() => {
        setValue(
            'packageParentId',
            packageSelected?.packageParent?.id ? packageSelected.packageParent.id : defaultValues.packageParentId,
        );
    }, [parentPackages]);

    return (
        <Form className="package" onSubmit={handleSubmit(onSubmit, onError)}>
            {(loading || loadingParentPackage) && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <Row className="mb-5">
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-wrench" />
                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.PACKAGE' })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Group controlId="packageCategoryId">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.FAMILY' })}</Form.Label>
                                <Form.Control
                                    name="packageCategoryId"
                                    as="select"
                                    className={`${errors.packageCategoryId ? 'is-invalid' : ''}`}
                                    ref={register({
                                        validate: (value) =>
                                            parseInt(value, 10) === -1
                                                ? Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                                : true,
                                    })}
                                >
                                    <option value={-1} disabled>
                                        {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.SELECT.FAMILY.DEFAULT' })}
                                    </option>
                                    {categories &&
                                        categories.map((category) => (
                                            <option key={`category-${category.id}-atelier`} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                </Form.Control>
                                <ErrorForm errors={errors} name="packageCategoryId" />
                            </Form.Group>
                            <Form.Group controlId="name">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.NAME' })}</Form.Label>
                                <Form.Control
                                    name="name"
                                    type="text"
                                    className={`${errors.name ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    })}
                                />
                                <ErrorForm errors={errors} name="name" />
                            </Form.Group>
                            <Form.Group controlId="code">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.CODE' })}</Form.Label>
                                <Form.Control
                                    name="code"
                                    type="text"
                                    className={`${errors.code ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    })}
                                />
                                <ErrorForm errors={errors} name="code" />
                            </Form.Group>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.STATUS' })}</Form.Label>
                                        <SwitchInput name="active" active={packageSelected?.active || false} />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.PROMOTE' })}
                                        </Form.Label>
                                        <SwitchInput name="promote" active={packageSelected?.promote || false} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.OPTION' })}</Form.Label>
                                        <SwitchInput
                                            name="isOption"
                                            form={false}
                                            active={isOption}
                                            labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
                                            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
                                            onChange={handleOptionChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    {isOption && (
                                        <Form.Group>
                                            <Form.Label>
                                                {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.PARENT_PACKAGE' })}
                                            </Form.Label>
                                            <Form.Control
                                                name="packageParentId"
                                                as="select"
                                                className={`${errors.packageCategoryId ? 'is-invalid' : ''}`}
                                                ref={register({
                                                    validate: (value) =>
                                                        parseInt(value, 10) === -1
                                                            ? Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                                            : true,
                                                })}
                                            >
                                                <option value={-1} disabled>
                                                    {Intl.formatMessage({
                                                        id: 'APV.PACKAGE.VIEW.PARENT_PACKAGE.PLACEHOLDER',
                                                    })}
                                                </option>
                                                {parentPackages?.length > 1 &&
                                                    parentPackages.map((pack) => (
                                                        <option key={`parent-${pack.id}-atelier`} value={pack.id}>
                                                            {pack.name}
                                                        </option>
                                                    ))}
                                            </Form.Control>
                                            <ErrorForm errors={errors} name="packageParentId" />
                                        </Form.Group>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.IS_DEGRESSIVITY' })}
                                        </Form.Label>
                                        <SwitchInput
                                            name="isDegressivity"
                                            labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
                                            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.MECAPLANNING_ACTION' })}
                                        </Form.Label>
                                        <Form.Control
                                            name="mecaplanningActionId"
                                            as="select"
                                            className={`${errors.mecaplanningActionId ? 'is-invalid' : ''}`}
                                            ref={register({
                                                validate: (value) =>
                                                    parseInt(value, 10) === -1
                                                        ? Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                                        : true,
                                            })}
                                        >
                                            <option value={-1} disabled>
                                                {Intl.formatMessage({
                                                    id: 'APV.PACKAGE.VIEW.MECAPLANNING_ACTION.PLACEHOLDER',
                                                })}
                                            </option>
                                            {mecaplanningActions?.length > 1 &&
                                                mecaplanningActions.map((action) => (
                                                    <option
                                                        key={`meca-${action.action_code}-atelier`}
                                                        value={action.id}
                                                    >
                                                        {action.name}
                                                    </option>
                                                ))}
                                        </Form.Control>
                                        <ErrorForm errors={errors} name="mecaplanningActionId" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.MO' })}</Form.Label>
                                        <Form.Control name="mo" type="text" ref={register} />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.TMO' })}</Form.Label>
                                        <Form.Control name="tmo" type="text" ref={register} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.RANK' })}</Form.Label>
                                        <Form.Control name="rank" type="text" ref={register} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.ADDONS' })}</Form.Label>
                                        <SwitchInput
                                            name="additionalServicesEligible"
                                            active={packageSelected?.additionalServicesEligible || false}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.AUTO_ADD_TO_CART' })}
                                        </Form.Label>
                                        <SwitchInput
                                            name="basketAutoAdd"
                                            active={packageSelected?.basketAutoAdd || false}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-globe-europe" />
                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.SEO' })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Group controlId="seoTitle">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.TITLE' })}</Form.Label>
                                <Form.Control
                                    name="seoTitle"
                                    type="text"
                                    className={`${errors.seoTitle ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    })}
                                />
                                <ErrorForm errors={errors} name="seoTitle" />
                            </Form.Group>
                            <Form.Group controlId="seoUrl">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.URL' })}</Form.Label>
                                <Form.Control
                                    name="seoUrl"
                                    type="text"
                                    className={`${errors.seoUrl ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    })}
                                />
                                <ErrorForm errors={errors} name="seoUrl" />
                            </Form.Group>
                            <Form.Group controlId="seoH1">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.H1' })}</Form.Label>
                                <Form.Control
                                    name="seoH1"
                                    type="text"
                                    className={`${errors.seoH1 ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    })}
                                />
                                <ErrorForm errors={errors} name="seoH1" />
                            </Form.Group>
                            <Form.Group controlId="seoMetaDescription">
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.META_DESCRIPTION' })}
                                </Form.Label>
                                <Form.Control
                                    name="seoMetaDescription"
                                    type="text"
                                    className={`${errors.seoMetaDescription ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    })}
                                />
                                <ErrorForm errors={errors} name="seoMetaDescription" />
                            </Form.Group>
                            <Form.Group controlId="pathVideo">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.VIDEO' })}</Form.Label>
                                <Form.Control name="pathVideo" type="text" ref={register} />
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-edit" />
                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.INTRODUCTION' })}
                        </Card.Header>
                        <Card.Body>
                            <Controller
                                control={control}
                                name="seoIntroduction"
                                rules={{
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={(props) => <Editor value={props.value} onChange={props.onChange} />}
                            />
                            <ErrorForm errors={errors} name="seoIntroduction" />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-edit" />
                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.DESCRIPTION' })}
                        </Card.Header>
                        <Card.Body>
                            <Controller
                                control={control}
                                name="seoDescription"
                                rules={{
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={(props) => <Editor value={props.value} onChange={props.onChange} />}
                            />
                            <ErrorForm errors={errors} name="seoDescription" />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon far fa-image" />
                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.VISUALS' })}
                        </Card.Header>
                        <Card.Body>
                            <PictureUpload name="pathImg" path={packageSelected?.pathImg} />
                            <ErrorForm errors={errors} name="pathImg" />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-money-bill" />
                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.PRICES' })}
                        </Card.Header>
                        <Card.Body>
                            <Controller
                                control={control}
                                name="prices"
                                rules={{
                                    validate: (value) => {
                                        let hasEmptyValue = false;

                                        for (const price of value) {
                                            if ((empty(price.code) || empty(price.price)) && price.active) {
                                                hasEmptyValue = true;
                                                break;
                                            }
                                        }

                                        return hasEmptyValue
                                            ? Intl.formatMessage({ id: 'FORM.ERROR.PRICES_REQUIRED' })
                                            : true;
                                    },
                                }}
                                render={(props) =>
                                    segments?.makeSegment?.map((makeSegment) => (
                                        <MakeSegments
                                            key={`segment-${makeSegment.id}-atelier`}
                                            segments={segments}
                                            name={props.name}
                                            id={makeSegment.id}
                                            title={makeSegment.name}
                                        />
                                    )) || null
                                }
                            />
                            <ErrorForm errors={errors} name="prices" />
                            <Row className="mt-4">
                                <Col className={newPackage ? 'text-center' : 'text-right'}>
                                    <Link to={routeTo(ROUTES.PACKAGES.PATH.TYPE, { type: 'workshop' })}>
                                        <Button variant="outline-primary">
                                            {Intl.formatMessage({ id: 'TRANSLATOR.BACK' })}
                                        </Button>
                                    </Link>
                                    <Permission permissions={['APV_UPDATE_PACKAGE']}>
                                        <Button variant="primary" className="ml-2" type="submit">
                                            {Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                                        </Button>
                                    </Permission>
                                </Col>
                                {!newPackage && (
                                    <Permission permissions={['APV_DELETE_PACKAGE']}>
                                        <Col className="text-right">
                                            <Button variant="outline-danger" onClick={() => deletePackage()}>
                                                {Intl.formatMessage({ id: 'TRANSLATOR.REMOVE' })}
                                            </Button>
                                        </Col>
                                    </Permission>
                                )}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <DevTool control={control} /> {/* set up the dev tool */}
        </Form>
    );
}

export default withRouter(Package);
