import dayjs from 'dayjs';

import { PartExchange } from '@app/crud/customers/customer.type';

import { toAbsoluteUrl } from '../../../../_metronic';

export const getPictureTemplate = (partExchange: PartExchange, defaultValue: string | null = null) => {
    if (partExchange?.image) {
        return partExchange.image;
    }
    return defaultValue !== null ? defaultValue : toAbsoluteUrl('/media/bg/default-veh-bg.svg');
};

export const getBrandTemplate = (partExchange: PartExchange, defaultValue = '-') => {
    if (partExchange?.make) {
        return partExchange.make;
    }
    return defaultValue;
};

export const getFuelTypeTemplate = (partExchange: PartExchange) => {
    if (partExchange?.fuelType) {
        return partExchange.fuelType;
    }
    return '-';
};

export const getGearsTypeTemplate = (partExchange: PartExchange) => {
    if (partExchange?.gearbox) {
        return partExchange?.gearbox;
    }
    return '-';
};

export const getProductionYearTemplate = (partExchange: PartExchange, defaultValue = '-') => {
    if (partExchange?.dateFirstRegistration) {
        return dayjs(partExchange?.dateFirstRegistration).format('YYYY');
    }
    return defaultValue;
};
