import React from 'react';
import { useIntl } from 'react-intl';

import { LeadDetails } from '@app/crud/customers/customer.type';

import { getVehiclePresence } from '@app/pages/customers/Helpers/Customer';
import StatusLead from '@app/pages/customers/Partials/StatusLead';

import RowInfo from '@app/partials/content/RowInfo';

type LeadLocalApvInfoProps = {
    event: LeadDetails;
};

const LeadLocalApvInfo = ({ event }: LeadLocalApvInfoProps) => {
    const Intl = useIntl();
    const apvRequest = event?.informations?.apvRequest;

    const leadStatus = (
        <StatusLead
            leadStatusUpdated={null}
            className="d-block d-xxl-flex align-items-center flex-wrap mt-2"
            statusSlug={event?.informations?.statusSlug}
            statusName={event?.informations?.status}
            statusSubName={event?.informations?.subStatus}
        />
    );

    return (
        <>
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DEALERSHIP' })}
                value={event?.informations?.dealership?.name}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.SERVICE' })}
                value={apvRequest?.service}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.REGISTRATION_PLATE' })}
                value={apvRequest?.vehicle_registration}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.BRAND' })}
                value={apvRequest?.make}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.MODEL' })}
                value={apvRequest?.model}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.TYPE' })}
                value={apvRequest?.action_type}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.SOURCE' })}
                value={apvRequest?.action_source}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.VEHICLE.AT.DEALERSHIP' })}
                value={getVehiclePresence(apvRequest?.vehicleInDealership)}
            />
            <RowInfo label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.STATUS' })} value={leadStatus} />
        </>
    );
};

export default LeadLocalApvInfo;
