import dayjs from 'dayjs';
import { Divider } from 'primereact/divider';
import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { LeadDetails } from '@app/crud/customers/customer.type';

import { getTypeSlug } from '@app/pages/customers/Helpers/Events';
import useLead from '@app/pages/customers/Hooks/useLead';
import StatusLead from '@app/pages/customers/Partials/StatusLead';
import { getFlagsIcon, LEAD_FLAGS } from '@app/pages/leadsOnline/Helpers/Flags';
import { leadTypes } from '@app/store/customers/customers.store';
import { actions as modalActions } from '@app/store/modal/modal.store';

import Label from '@app/partials/content/Label';
import Permission from '@app/partials/content/Permission';
import RowInfo from '@app/partials/content/RowInfo';

import Price from '@app/components/formatters/price/Price';

type LeadInfoProps = {
    event: LeadDetails;
    isLeadClosed: boolean;
};

const LeadInfo = ({ event, isLeadClosed }: LeadInfoProps) => {
    const { informations, finance, prestations } = event;
    const dispatch = useDispatch();
    const currentLead = useLead(informations.identifier);
    const typeSlug = getTypeSlug(informations?.processType, informations?.leadTypeSlug, informations?.source);
    const isLeadAudi = typeSlug === 'constructeur' && currentLead?.leadOrigin?.slug === 'audi.fr';
    const isLeadFid = typeSlug === leadTypes.fidVn || typeSlug === leadTypes.fidVo;
    const isLeadBMW = currentLead?.leadSource?.slug === 'bmw_group';

    const Intl = useIntl();

    const showAssign = () => {
        dispatch(
            modalActions.modalChange('assign_lead', {
                ...(informations?.userFullname ? { update: true } : {}),
                leadId: informations?.identifier,
            }),
        );
    };

    const showUpdateStatus = () => {
        dispatch(
            modalActions.modalChange('update_lead_status', {
                leadId: informations?.identifier,
                lead: informations,
            }),
        );
    };

    const getCallTransfert = () => {
        let transfert: string | JSX.Element = '-';
        if (informations?.callTransfer && LEAD_FLAGS?.[informations?.callTransfer]) {
            let variant = 'success';
            if (informations?.callTransfer === 'transfert-a-echoue') {
                variant = 'danger';
            } else if (informations?.callTransfer === 'transfert-refuse-par-le-conseiller') {
                variant = 'warning';
            }

            transfert = (
                <div className="d-flex">
                    <Label className="d-flex font-weight-bold align-items-center" variant={variant}>
                        {LEAD_FLAGS?.[informations?.callTransfer]?.icon}
                        {LEAD_FLAGS?.[informations?.callTransfer]?.name}
                    </Label>
                </div>
            );
        }
        return transfert;
    };

    const homeTrial = () => {
        if (informations?.homeTrial !== null && informations?.homeTrial !== undefined) {
            if (informations?.homeTrial) {
                return <span className="text-success">Oui</span>;
            }

            return <span className="text-danger">Non</span>;
        }
        return '-';
    };

    return (
        <>
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.ID' })}
                value={informations?.identifier || '-'}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.TYPE' })}
                value={
                    <>
                        <Label
                            className={`font-weight-bold event__origin--${typeSlug} event__origin-bg-opacity--${typeSlug}`}
                        >
                            {informations?.leadType || '-'}
                        </Label>
                        <span className="ml-2">
                            {getFlagsIcon(informations, ['strong_commitment', 'hight_interest_level'])}
                        </span>
                    </>
                }
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.SOURCE' })}
                value={
                    informations?.source ? (
                        <>
                            <span>{informations?.source}</span>
                            {informations?.leadOrigin && informations?.leadOrigin !== informations?.source && (
                                <Permission permissions={['LEAD_SHOW_SOURCE']}>
                                    <span> - {informations?.leadOrigin}</span>
                                </Permission>
                            )}
                            {informations?.operations &&
                                informations?.operations?.map((operation) => (
                                    <>
                                        <br />
                                        <Label
                                            key={operation.slug}
                                            className="font-weight-bold label-lg label-light-success"
                                        >
                                            {operation?.name}
                                        </Label>
                                    </>
                                ))}
                        </>
                    ) : (
                        '-'
                    )
                }
            />
            {isLeadFid && (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.FINANCIAL_TYPE' })}
                    value={finance?.financialType ? <span>{finance?.financialType}</span> : '-'}
                />
            )}
            {isLeadFid && finance?.financialRent != null && (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.FINANCIAL_RENT' })}
                    value={<Price value={finance.financialRent} />}
                />
            )}
            {isLeadFid && finance?.residualValue != null && (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.FINANCIAL_RESIDUAL_VALUE' })}
                    value={<Price value={finance.residualValue} />}
                />
            )}
            {isLeadFid && finance?.financialInput != null && (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.FINANCIAL_INPUT' })}
                    value={<Price value={finance.financialInput} />}
                />
            )}
            {isLeadFid && finance?.financingAgencyLeadWon && (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.FINANCING_AGENCY' })}
                    value={<span className="text-uppercase">{finance.financingAgencyLeadWon}</span>}
                />
            )}
            {!isLeadFid && (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.CONTACT_TYPE' })}
                    value={
                        informations?.contactType ? (
                            <Label
                                className={`font-weight-bold event__origin--${typeSlug} event__origin-bg-opacity--${typeSlug}`}
                            >
                                {informations?.contactType}
                                {informations?.contactSlug === 'appointment' &&
                                    ['achat_cash', 'achat_cash_abandon'].includes(informations?.leadTypeSlug) &&
                                    ` ${Intl.formatMessage({
                                        id: `CUSTOMER.VIEW.LIFE.DETAIL.INFO.CONTACT_TYPE.APPOINTMENT.${
                                            ['MUL033'].includes(informations?.dealership?.identifier)
                                                ? 'REMOTE'
                                                : 'LIVE'
                                        }`,
                                    })}`}
                            </Label>
                        ) : (
                            '-'
                        )
                    }
                />
            )}
            {isLeadFid && (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.NUMBER_CONTRACT' })}
                    value={finance?.contractNumber ?? '-'}
                />
            )}
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DATE_CREATED' })}
                value={
                    informations?.receipted
                        ? dayjs(informations?.receipted, 'DD/MM/YYYY - HH [h] mm').format('DD/MM/YYYY [à] HH [h] mm')
                        : '-'
                }
            />
            {isLeadFid && (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.START_CONTRACT' })}
                    value={finance?.contractStartDate ? dayjs(finance?.contractStartDate).format('DD/MM/YYYY') : '-'}
                />
            )}
            {isLeadFid && (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.END_CONTRACT' })}
                    value={
                        finance?.contractEndDate ? (
                            <Label className="font-weight-bold label-lg label-light-warning">
                                {dayjs(finance?.contractEndDate).format('DD/MM/YYYY')}
                            </Label>
                        ) : (
                            '-'
                        )
                    }
                />
            )}
            {isLeadFid && finance?.contractExtensionDate && (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.END_CONTRACT_EXTEND' })}
                    value={
                        <Label className="font-weight-bold label-lg label-light-warning">
                            {dayjs(finance?.contractExtensionDate).format('DD/MM/YYYY')}
                        </Label>
                    }
                />
            )}

            {isLeadFid && prestations && (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.PRESTATIONS' })}
                    value={
                        prestations.length > 0
                            ? prestations.map((prestation) => (
                                  <div>
                                      {prestation?.name && <span>{prestation.name}</span>}
                                      {prestation?.price != null && (
                                          <>
                                              <span> - </span>
                                              <Price value={prestation.price} />
                                          </>
                                      )}

                                      {prestation?.description && (
                                          <>
                                              <br />
                                              <span>{`(${prestation.description})`}</span>
                                          </>
                                      )}
                                  </div>
                              ))
                            : '-'
                    }
                />
            )}

            <Divider className="p-divider-dashed" />

            {informations?.leadTypeSlug !== 'local' &&
                informations?.leadTypeSlug !== 'apv' &&
                isLeadAudi !== undefined &&
                !isLeadAudi && (
                    <RowInfo
                        label={<FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.INFO.TRANSFERT_CALL" />}
                        value={getCallTransfert()}
                    />
                )}
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.RDV' })}
                value={informations?.rdv || '-'}
            />
            {!['local', 'apv', 'constructeur'].includes(informations?.leadTypeSlug) && (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.HOME_TRIAL' })}
                    value={homeTrial()}
                />
            )}
            {!['local', 'apv', 'constructeur'].includes(informations?.leadTypeSlug) && (
                <RowInfo
                    label={<FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.INFO.COMMUNICATION" />}
                    value={informations?.communicationChannel || '-'}
                />
            )}
            <RowInfo
                label={
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                        {Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.FOLLOW_BY' })}
                        {!isLeadClosed && !isLeadBMW && !informations?.processType && (
                            <Permission
                                permissions={['LEAD_AFFECT_LEAD']}
                                interceptor={() => currentLead?.leadParentId}
                            >
                                <Button variant="primary" className="p-1 d-flex" size="sm" onClick={showAssign}>
                                    {informations?.userFullname ? (
                                        <>
                                            <i className="las la-sync" />
                                            {Intl.formatMessage({ id: 'TRANSLATOR.CHANGE' })}
                                        </>
                                    ) : (
                                        Intl.formatMessage({ id: 'TRANSLATOR.ASSIGN' })
                                    )}
                                </Button>
                            </Permission>
                        )}
                    </div>
                }
                value={informations?.userFullname || '-'}
            />
            {informations?.createdBy && (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.CREATED_BY' })}
                    value={`${informations?.createdBy?.firstname} ${informations?.createdBy?.lastname}`}
                />
            )}
            <RowInfo
                label={
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                        {Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.STATUS' })}
                        {isLeadClosed && !informations?.processType && (
                            <Permission
                                permissions={['LEAD_CHANGE_STATUT']}
                                interceptor={() => currentLead?.leadParentId}
                            >
                                <Button variant="primary" className="p-1 d-flex" size="sm" onClick={showUpdateStatus}>
                                    <i className="las la-sync" />
                                    {Intl.formatMessage({ id: 'TRANSLATOR.CHANGE' })}
                                </Button>
                            </Permission>
                        )}
                    </div>
                }
                value=""
                subComponent={
                    <StatusLead
                        leadStatusUpdated={null}
                        statusSlug={informations?.statusSlug}
                        statusName={informations?.leadClosLibel || informations?.status}
                        statusSubName={informations?.subStatus}
                    />
                }
                status
            />

            {isLeadFid && (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.RESIDUAL_BLOCK' })}
                    value={
                        finance?.isResidualBlocked
                            ? Intl.formatMessage({ id: 'TRANSLATOR.YES' })
                            : Intl.formatMessage({ id: 'TRANSLATOR.NO' })
                    }
                />
            )}

            <Divider className="p-divider-dashed" />

            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DEALERSHIP' })}
                value={
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="dealership-tooltip">{informations?.dealership?.name}</Tooltip>}
                    >
                        <span className="cursor-pointer">{informations?.dealership?.name || '-'}</span>
                    </OverlayTrigger>
                }
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.CITY' })}
                value={
                    informations?.dealership.zipcode || informations?.dealership?.city
                        ? `${informations?.dealership?.zipcode || ''} ${informations?.dealership?.city || ''}`
                        : '-'
                }
            />
        </>
    );
};

export default LeadInfo;
