import { DataTable } from 'primereact/datatable';
import React, { useRef } from 'react';

const HoCDataTable = React.forwardRef(({ children, fixedHeader, fixedHeight, ...props }, forwardedRef) => {
    const internalRef = useRef();

    /**
     * Override mousedown prime react event to handle copy / past
     * Impossible to use with context menu
     *
     * @param ref
     */
    const overrideEventHandler = (ref) => {
        const body = ref?.table?.querySelector?.('.p-datatable-tbody');
        body?.addEventListener?.('mousedown', (e) => {
            if (e?.which === 3) {
                e.stopPropagation();
            }
        });
    };

    /**
     * Handle forwarded reference
     *
     * @param ref
     */
    const refHandler = (ref) => {
        if (forwardedRef) {
            forwardedRef.current = ref;
        }

        if (internalRef) {
            internalRef.current = ref;
        }

        overrideEventHandler(ref);
    };
    return (
        <DataTable
            {...props}
            scrollable={fixedHeader}
            scrollHeight={fixedHeader && fixedHeight}
            rowClassName={(row) => {
                const id = row?.id || '';
                const initialClass = props?.rowClassName?.(row) || {};

                return {
                    [id]: id,
                    ...initialClass,
                };
            }}
            ref={refHandler}
        >
            {children}
        </DataTable>
    );
});

HoCDataTable.defaultProps = {
    fixedheader: 'false',
    fixedheight: '500px',
};

export default HoCDataTable;
