import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext, UseFormMethods } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { alphabeticalSort } from '@app/helpers/ArrayHelper';

import { Customer } from '@app/crud/customers/customer.type';

import { useAppSelector } from '@app/hooks';
import { Lead } from '@app/store/customers/customers.store';

import ErrorForm from '@app/partials/layout/ErrorForm';

type DealershipSelectProps = {
    methods: UseFormMethods;
    customer: Customer;
    additionnalClass?: string;
    currentLead?: Lead;
    value?: string;
    forceDisplay?: boolean;
    required?: boolean;
};

/** *
 * Select element to select dealership in customer detail modals
 * @param methods
 * @param customer
 * @param additionnalClass
 * @param currentLead
 * @returns {JSX.Element}
 * @constructor
 */
const DealershipSelect = ({
    methods,
    customer,
    additionnalClass,
    currentLead,
    value,
    forceDisplay,
    required,
}: DealershipSelectProps) => {
    const Intl = useIntl();
    const { userData } = useAppSelector((state) => state.auth);
    const { control } = methods;
    const { setValue } = useFormContext();
    const [toDisplay, setToDisplay] = React.useState(true);

    React.useEffect(() => {
        let dealership;
        const refDealershipId = currentLead?.dealershipId;

        if (customer && userData && currentLead) {
            if (refDealershipId && userData?.dealerships?.[refDealershipId] !== undefined) dealership = refDealershipId;
            else if (userData?.default_dealership) dealership = userData.default_dealership;
        }

        if (dealership !== undefined) setValue('dealership', dealership);
    }, [customer, userData, currentLead]);

    React.useEffect(() => {
        if (userData?.dealerships && !(Object.keys(userData.dealerships).length > 1) && !forceDisplay) {
            setToDisplay(false);
        }
    }, [userData]);

    return (
        <Form.Group className={additionnalClass + (toDisplay ? '' : ' hidden-component')}>
            <Form.Label>
                {Intl.formatMessage({
                    id: 'CUSTOMERS.MODAL.UPDATE.DEALERSHIP',
                })}
            </Form.Label>
            <Controller
                control={control}
                name="dealership"
                defaultValue={value || userData.default_dealership}
                rules={{
                    required: required && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                }}
                render={(renderProps) => (
                    <Dropdown
                        value={renderProps.value}
                        className={methods.errors?.dealership && 'is-invalid'}
                        options={
                            userData?.dealerships &&
                            Object.entries(userData.dealerships)
                                .sort(alphabeticalSort('title'))
                                .map(([code, dealership]) => ({
                                    ...dealership,
                                    code,
                                }))
                        }
                        onChange={(e) => renderProps.onChange(e.value)}
                        optionLabel="title"
                        optionValue="code"
                        filter
                        required={required}
                        filterBy="title"
                        disabled={!!currentLead}
                        valueTemplate={(value) => value?.title || ''}
                    />
                )}
            />
            <ErrorForm errors={methods.errors} name="dealership" />
        </Form.Group>
    );
};

DealershipSelect.defaultProps = {
    additionnalClass: '',
    currentLead: null,
    forceDisplay: false,
    required: true,
};

export default DealershipSelect;
