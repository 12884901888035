import React, { useEffect } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import useFetch from '@app/hooks/useFetch';

import { getUserPartner, updateUserPartner } from '@app/crud/autouser/user.crud';
import { UpdateUserPartnerParams, UserPartnerAttributeSlug } from '@app/crud/autouser/user.types';
import { TchekUser } from '@app/crud/customers/tchek.crud';

import variantTypes from '@app/constants';
import { useAppSelector } from '@app/hooks';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

import TchekUserSelector from './TchekUserSelector';

interface ManageUserPartnerProps {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
}

const ManageUserPartner = ({ showModal, setShowModal }: ManageUserPartnerProps) => {
    const Intl = useIntl();
    const methods = useForm();
    const { register, handleSubmit, setValue } = methods;
    const { params } = useAppSelector((state) => state.modal);
    const userId = params?.user?.id;

    const {
        fetch: fetchUserPartner,
        data: userPartner,
        loading: loadingUserPartner,
    } = useFetch({
        fetchAction: getUserPartner,
        resultInterceptor: (response) => response,
    });

    useEffect(() => {
        if (userId) {
            fetchUserPartner(userId);
        }
    }, [params]);

    const onSubmit = (form_values: UpdateUserPartnerParams['attributes']) => {
        if (userId) {
            updateUserPartner(userId, form_values)
                .then(() => {
                    toast({
                        variant: variantTypes.SUCCESS,
                        message: Intl.formatMessage({ id: 'TRANSLATOR.SUCCESS' }),
                    });
                })
                .catch(() => {
                    toast({
                        variant: variantTypes.DANGER,
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                })
                .finally(() => {
                    setShowModal(false);
                    userPartner.salesforce_bmw_id = null;
                    userPartner.tchek_id = null;
                });
        }
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loadingUserPartner}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-user" />}
            title={Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.PARTNER.TITLE' })}
            body={
                <FormProvider {...methods}>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="AUTOUSER.USER.MODAL.MANAGE.PARTNER.BMW_ID" />
                            </Form.Label>
                            <Form.Control
                                ref={register}
                                name={UserPartnerAttributeSlug.SALESFORCE_BMW_ID}
                                defaultValue={userPartner.salesforce_bmw_id}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="AUTOUSER.USER.MODAL.MANAGE.PARTNER.TCHEK_ID" />
                            </Form.Label>
                            <div className="input-group">
                                <Form.Control
                                    ref={register}
                                    name={UserPartnerAttributeSlug.TCHEK_ID}
                                    defaultValue={userPartner.tchek_id}
                                />
                                <div className="input-group-append">
                                    <TchekUserSelector
                                        onSelected={(user: TchekUser) =>
                                            setValue(UserPartnerAttributeSlug.TCHEK_ID, user.id)
                                        }
                                    />
                                </div>
                            </div>
                        </Form.Group>
                    </Col>
                </FormProvider>
            }
            footer={
                <div className="d-flex justify-content-between float-right">
                    <Button className="mr-5" variant="outline-secondary" onClick={() => setShowModal(false)}>
                        <FormattedMessage id="TRANSLATOR.CANCEL" />
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                        <FormattedMessage id="TRANSLATOR.VALIDATE" />
                    </Button>
                </div>
            }
        />
    );
};

export default ManageUserPartner;
