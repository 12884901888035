import React, { useCallback, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { CustomerEvent, EventDetailType } from '@app/crud/customers/customer.type';

import RappelClientProject from './EventDetailProject/RappelClientProject';
import VehicleProject from './EventDetailProject/VehicleProject';

type EventDetailProjectProps = {
    event: EventDetailType;
    typeSlug: string;
    isLeadClosed: boolean;
    isVcuEvent: boolean;
    activeTab: string;
    data: CustomerEvent;
};

const EventDetailProject = ({
    event,
    typeSlug,
    isLeadClosed,
    isVcuEvent,
    activeTab,
    data,
}: EventDetailProjectProps) => {
    const Intl = useIntl();
    const [header, setHeader] = useState({ icone: 'la-lightbulb', title: 'VEHICLE' });
    const [template, setTemplate] = useState(null);

    const leadTypeCase = useCallback(() => {
        switch (event?.informations?.leadTypeSlug) {
            case 'rappel_client':
                setHeader({ icone: 'la-bullhorn', title: 'DEALERSHIP_ACTION' });
                return <RappelClientProject event={event} />;
            default:
                break;
        }
        return (
            <VehicleProject
                event={event}
                typeSlug={typeSlug}
                isLeadClosed={isLeadClosed}
                isVcuEvent={isVcuEvent}
                activeTab={activeTab}
                data={data}
            />
        );
    }, [event, isLeadClosed, typeSlug]);

    useEffect(() => {
        setTemplate(leadTypeCase());
    }, [leadTypeCase, setTemplate]);

    return (
        <Card className="card--shadowed">
            <Card.Header className={`event__origin--${typeSlug} position-relative`}>
                <div className={`event__origin-bg--${typeSlug} pin`}>
                    <i className={`la ${header.icone} text-white la-lg`} />
                </div>
                <span className="ml-4">
                    {Intl.formatMessage({ id: `CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.TITLE.${header.title}` })}
                </span>
            </Card.Header>
            <Card.Body>{template}</Card.Body>
        </Card>
    );
};

export default EventDetailProject;
