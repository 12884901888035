import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useTags from '@app/pages/customers/Hooks/useTags';
import LeadStatusRow from '@app/pages/customers/Partials/LeadStatusRow';
import TagsList from '@app/pages/customers/Partials/TagsList';
import { Lead } from '@app/store/customers/customers.store';

type LeadRowContentProps = {
    lead: Lead;
};

const LeadRowContent = ({ lead }: LeadRowContentProps) => {
    const { tags, selectedTags, linkTagToLead, unlinkTagToLead } = useTags({
        lead,
    });

    return (
        <>
            <div className="mb-lg-0 mb-2">
                <span className="font-size-h3 font-weight-bold text-nowrap">{lead.leadType.name}</span>
                {lead?.leadStatus && (
                    <LeadStatusRow
                        leadStatusSlug={lead?.leadStatus?.slug}
                        leadStatusName={lead?.leadStatus?.name}
                        leadStatusSubName={lead?.leadStatus?.subName}
                        leadFlags={lead?.flags}
                        shift="ml-0"
                        className="d-flex align-items-center mb-1"
                    />
                )}
            </div>
            <TagsList
                lead={lead}
                tags={tags}
                selectedTags={selectedTags}
                limited
                linkTagToLead={linkTagToLead}
                unlinkTagToLead={unlinkTagToLead}
            />
            {lead?.scheduleAppointment?.dateStart && (
                <div className="font-weight-bold">
                    le {dayjs(lead.scheduleAppointment.dateStart).format('DD/MM/YYYY [à] HH [h] mm')}
                </div>
            )}
            <div>
                {lead?.dealershipName ? `${lead.dealershipName}` : ''}
                {lead?.leadElements[0] && (
                    <>
                        {' - '}
                        {lead.leadElements[0]?.vehicle?.make || ''} {lead.leadElements[0]?.vehicle?.model || ''}{' '}
                        {lead.leadElements[0]?.vehicle?.registration ||
                            lead.leadElements[0]?.vehicle?.serialNumber ||
                            ''}
                    </>
                )}
            </div>
            {lead?.updated && (
                <div>
                    <FormattedMessage id="CUSTOMERS.LEAD_ROW.LAST_UPDATE" />{' '}
                    {dayjs(lead.updated).format('DD/MM/YYYY [à] HH [h] mm')}
                </div>
            )}
            {lead?.ownerFirstname && lead?.ownerLastname && (
                <div>
                    <FormattedMessage id="CUSTOMERS.LEAD_ROW.REFERENT" /> : {lead.ownerFirstname} {lead.ownerLastname}
                </div>
            )}
            {lead?.createdFirstname && lead?.createdLastname && (
                <div>
                    <FormattedMessage id="CUSTOMERS.LEAD_ROW.CREATED_BY" /> : {lead?.createdFirstname}{' '}
                    {lead?.createdLastname}
                </div>
            )}
        </>
    );
};

export default LeadRowContent;
