// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { createHomeChargeLead } from '@app/crud/homeCharge/home-charge.crud';

import { useAppSelector } from '@app/hooks';
import { refreshLeads } from '@app/store/customers/customers.thunk';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import ModalStepper, { ModalStepperType } from '@app/partials/content/modals/ModalStepper';
import toast from '@app/partials/content/Toast';

import { CreateLeadHomeChargePayload } from './LeadHomeCharge.type';
import { LeadHomeChargeCustomer, LeadHomeChargeDealership, LeadHomeChargeVehicle } from './partials';

type LeadHomeChargeProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
};

export interface LeadHomeChargeFormFields {
    make?: string;
    model?: string;
    isProValue?: string;
}

const LeadHomeCharge = ({ showModal, setShowModal }: LeadHomeChargeProps) => {
    const dispatch = useDispatch();
    const Intl = useIntl();
    const methods = useForm();
    const [loading, setLoading] = useState(false);
    const { handleSubmit, control } = methods;
    const { data: customer } = useAppSelector((state) => state.customer.customer);
    const [data, setData] = useState<CreateLeadHomeChargePayload>();
    const [stepperState, setStepperState] = useState<ModalStepperType>({
        currentStep: 0,
        steps: [
            {
                name: 'Concession',
                status: 'valid',
            },
            {
                name: 'Coordonnées',
                status: 'pending',
            },
            {
                name: 'Véhicule',
                status: 'pending',
            },
        ],
    });

    const onSubmit = (stepData: CreateLeadHomeChargePayload & LeadHomeChargeFormFields) => {
        setLoading(true);

        // handle make coming from BrandModelVersion
        if (stepData.make) {
            stepData.vehicleBrand = stepData.make;
            delete stepData.make;
        }

        // handle model coming from BrandModelVersion
        if (stepData.model) {
            stepData.vehicleModel = stepData.model;
            delete stepData.model;
        }

        const payloadData = {
            ...data,
            ...stepData,
            // @ts-ignore
            isPro: data.isProValue === 'true',
        };

        const dealershipId = payloadData.dealership;
        delete payloadData.dealership;
        delete payloadData.isProValue;

        createHomeChargeLead(customer.id, dealershipId, payloadData)
            .then((response) => {
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'CUSTOMER.MODAL.LEAD_HOME_CHARGE.SUCCESS' }),
                });

                dispatch(refreshLeads(customer.id, 'local_home_charge'));

                if (response.result?.homeCharge?.url) window.open(response.result.homeCharge.url);

                setShowModal(false);
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updateData = (stepData: Partial<CreateLeadHomeChargePayload>) => {
        setData((stateData) => ({
            ...stateData,
            ...stepData,
        }));
        setStepperState((state) => {
            const newStepperState = { ...state };
            newStepperState.steps[state.currentStep].status = 'valid';
            newStepperState.currentStep += 1;
            return newStepperState;
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    return (
        <ModalDefault
            size="lg"
            show={showModal}
            loading={loading}
            hideModal={() => {
                setShowModal(false);
                setStepperState((state) => ({
                    ...state,
                    currentStep: 0,
                }));
            }}
            icon={<i className="la la-2x text-primary la-plug" />}
            title={Intl.formatMessage({
                id: 'CUSTOMER.MODAL.LEAD_HOME_CHARGE.TITLE',
            })}
            body={
                <>
                    {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
                    <ModalStepper state={stepperState} />
                    <FormProvider {...methods}>
                        {stepperState.currentStep === 0 && <LeadHomeChargeDealership data={data} />}
                        {stepperState.currentStep === 1 && <LeadHomeChargeCustomer data={data} />}
                        {stepperState.currentStep === 2 && <LeadHomeChargeVehicle />}
                    </FormProvider>
                </>
            }
            footer={
                <div className="d-flex justify-content-between">
                    {stepperState.currentStep > 0 ? (
                        <Button
                            variant="outline-primary"
                            onClick={() =>
                                setStepperState((state) => ({
                                    ...state,
                                    currentStep: state.currentStep - 1,
                                }))
                            }
                            className="mr-5"
                        >
                            <FormattedMessage id="TRANSLATOR.PREVIOUS" />
                        </Button>
                    ) : (
                        <Button variant="outline-primary" onClick={() => setShowModal(false)} className="mr-5">
                            <FormattedMessage id="TRANSLATOR.CANCEL" />
                        </Button>
                    )}
                    {stepperState.currentStep < stepperState.steps.length - 1 ? (
                        <Button variant="outline-primary" onClick={handleSubmit(updateData, onError)}>
                            <FormattedMessage id="TRANSLATOR.NEXT" />
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                            <FormattedMessage id="TRANSLATOR.VALIDATE" />
                        </Button>
                    )}
                </div>
            }
        />
    );
};

export default LeadHomeCharge;
