import classNames from 'classnames';
import * as dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React, { useContext } from 'react';
import { Button, Image } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import getTypeColor from '@app/pages/stock/helpers/StockHelper';
import PublishedLabel from '@app/pages/stock/partials/PublishedLabel';
import UnPublishedLabel from '@app/pages/stock/partials/UnPublishedLabel';
import StockVehiclesFundingColumn from '@app/pages/stock/vehicles/dataTable/column/StockVehiclesFundingColumn';
import ROUTES from '@app/router/Routes';
import { actions as modalActions } from '@app/store/modal/modal.store';

import { FiltersContext } from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import Label from '@app/partials/content/Label';
import {
    getBrandTemplate,
    getPictureTemplate,
    getRegistrationOrSerialNumberTemplate,
    getVehicleInternalIdTemplate,
} from '@app/partials/content/Vehicle/VehicleHelpers';

const StockActionsTodoDatatable = ({
    actions,
    handleFilter,
    handlePagination,
    handleSort,
    loading,
    total,
    items,
    start,
    sortField,
    sortOrder,
    isHistory,
}) => {
    const dispatch = useDispatch();
    const getVehicle = (action) => action?.vehicle;
    const getLabel = (action) => action?.reason?.label;
    const filters = useContext(FiltersContext);

    const vehicleBodyTemplate = (action) => {
        const vehicle = getVehicle(action);
        return (
            <div className="d-flex align-items-center">
                {getPictureTemplate(vehicle, '') && (
                    <Image className="action-todo__picture mr-2" src={getPictureTemplate(vehicle)} />
                )}
                <div>
                    <div className="text-primary">
                        <Link
                            target="_blank"
                            to={routeTo(ROUTES.STOCK_VEHICLE.PATH, {
                                sku: vehicle?.sku,
                            })}
                        >
                            <u>
                                {getBrandTemplate(vehicle)} {vehicle?.model}
                            </u>
                        </Link>
                    </div>
                    <div>{vehicle?.version}</div>
                    <div className="mt-2 d-flex flex-wrap align-items-center">
                        <div className="mr-2">{getRegistrationOrSerialNumberTemplate(vehicle)}</div>
                        <Label variant="secondary" className="mr-2 text-initial font-weight-semibold">
                            {getVehicleInternalIdTemplate(vehicle)}
                        </Label>
                        <Label variant="secondary" className="mr-2 text-initial font-weight-semibold">
                            {vehicle?.police_number}
                        </Label>
                        <Label
                            className={classNames('mr-2 font-weight-semibold', {
                                [`label-${getTypeColor(vehicle?.type)}`]: true,
                            })}
                        >
                            {vehicle?.type}
                        </Label>
                        <Label variant="secondary" className="text-initial font-weight-semibold">
                            <NumberFormat
                                value={vehicle.price}
                                decimalSeparator="."
                                thousandSeparator=" "
                                suffix=" €"
                                displayType="text"
                            />
                        </Label>
                    </div>
                </div>
            </div>
        );
    };

    const stateBodyTemplate = (action) => {
        const vehicle = getVehicle(action);
        const label = getLabel(action);
        const isFundingType = action?.type?.slug === 'funding';
        const isBloquedFullWeb = filters?.type?.value === 2;

        return (
            <div className="d-flex flex-wrap align-items-center">
                <div className="mr-2 mb-2">
                    {vehicle?.statut === 'Publié' ? (
                        <PublishedLabel variant="success" />
                    ) : (
                        <>
                            <UnPublishedLabel vehicle={vehicle} label={label} isBloquedFullWeb={isBloquedFullWeb} />
                            {isBloquedFullWeb && (
                                <div className="text-danger">
                                    {'\u2022'} {label}
                                </div>
                            )}
                        </>
                    )}
                </div>
                {isFundingType && <StockVehiclesFundingColumn vehicle={vehicle} rowsClassNames="mb-2 mr-2" />}
            </div>
        );
    };

    const furtherInfosBodyTemplate = (action) => {
        const publications = action?.data || [];

        return (
            <>
                <div>{action?.reason?.label}</div>
                <div className="" />
                {action?.type?.slug === 'fullweb_locked' ? (
                    <>
                        <div
                            className="text-danger"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: action?.reason?.description }}
                        />
                        <div className="text-danger">{action?.reason?.description}</div>
                    </>
                ) : (
                    publications.length > 0 &&
                    publications?.map?.((publication, idx) => (
                        <div key={idx} className="text-danger">
                            &bull; {publication}
                        </div>
                    ))
                )}
            </>
        );
    };

    const infosBodyTemplate = (action) => (
        <>
            <div className="text-center">
                {action?.firstname} {action?.lastname}
            </div>
            <Label variant="primary" className="font-weight-bold">
                {dayjs(action?.inactive_at?.date).format('DD/MM/YYYY[ à ] HH [h] mm')}
            </Label>
        </>
    );

    const actionsBodyTemplate = (action) => {
        const definition = action?.reason?.action?.definition;

        return (
            <Button
                size="sm"
                onClick={() => {
                    dispatch(
                        modalActions.modalChange('stock_action_detail', {
                            anomaly: action,
                        }),
                    );
                }}
            >
                {definition?.name}
            </Button>
        );
    };

    const actionsCompletedBodyTemplate = (action) => {
        const publications = action?.data || [];
        return (
            <>
                {action?.type?.slug === 'fullweb_locked' ? (
                    // eslint-disable-next-line react/no-danger
                    <div dangerouslySetInnerHTML={{ __html: action?.reason?.description }} />
                ) : (
                    publications.length > 0 &&
                    publications?.map?.((publication, idx) => <div key={idx}>&bull; {publication}</div>)
                )}
                <div className="text-success">{action?.reason?.action?.definition?.modal?.button?.description}</div>
            </>
        );
    };

    const cols = [
        {
            field: 'vehicle',
            header: <FormattedHTMLMessage id="STOCK_VEHICLES.ACTIONS.TABLE.VEHICLE" />,
            body: vehicleBodyTemplate,
        },
        {
            field: 'state',
            header: <FormattedHTMLMessage id="STOCK_VEHICLES.ACTIONS.TABLE.STATE" />,
            body: stateBodyTemplate,
            style: { width: '20%' },
        },
        !isHistory && {
            field: 'further_infos',
            header: <FormattedHTMLMessage id="STOCK_VEHICLES.ACTIONS.TABLE.FURTHER_INFOS" />,
            body: furtherInfosBodyTemplate,
        },
        !isHistory && {
            field: 'actions',
            header: <FormattedHTMLMessage id="STOCK_VEHICLES.ACTIONS.TABLE.ACTIONS" />,
            body: actionsBodyTemplate,
            style: { width: '10%' },
        },
        isHistory && {
            field: 'actions_completed',
            header: <FormattedHTMLMessage id="STOCK_VEHICLES.ACTIONS.TABLE.ACTIONS_COMPLETED" />,
            body: actionsCompletedBodyTemplate,
        },
        isHistory && {
            field: 'infos',
            header: <FormattedHTMLMessage id="STOCK_VEHICLES.ACTIONS.TABLE.INFOS" />,
            body: infosBodyTemplate,
            style: { width: '10%' },
        },
    ];

    const dynamicColumns = cols.map((col) => col && <Column key={col.field} {...col} />);

    return (
        <HoCDataTable
            value={actions || []}
            totalRecords={total || 0}
            lazy
            loading={loading}
            paginator
            onFilter={handleFilter}
            onPage={handlePagination}
            onSort={handleSort}
            paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
            currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
            rows={items}
            first={start}
            sortField={sortField}
            sortOrder={sortOrder}
            removableSort
            rowsPerPageOptions={[25, 50, 100]}
            emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
        >
            {dynamicColumns}
        </HoCDataTable>
    );
};

export default StockActionsTodoDatatable;
