import { AnimateSharedLayout, motion } from 'framer-motion';
import produce from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import useTypologies from '@app/hooks/useTypologies';

import { useAppSelector } from '@app/hooks';
import { actionsSlug, FormattedActionData, getActions } from '@app/pages/customers/Helpers/Actions';
import useAction from '@app/pages/customers/Hooks/useAction';
import { leadLocalTypes } from '@app/store/customers/customers.store';
import { actions } from '@app/store/modal/modal.store';

import ActionButton from '@app/partials/content/ActionButton';
import Loader from '@app/partials/content/Loader';
import toast from '@app/partials/content/Toast';

const container = {
    hidden: { opacity: 1 },
    visible: {
        opacity: 1,
        transition: {
            delay: 1,
            staggerChildren: 0.15,
        },
    },
};

const ActionsHandBand = <K extends keyof typeof actionsSlug>({ actionTypes }: { actionTypes: K[] }) => {
    const Intl = useIntl();
    const { hasAnyTypologies } = useTypologies();
    const ktContent = document.getElementById('kt_content');

    const dispatch = useDispatch();
    const { userData } = useAppSelector((state) => state.auth);

    const headBandActions = getActions(actionTypes);

    const [loading, createAction] = useAction({
        type: 'init',
        dataInterceptor: (data: FormattedActionData) =>
            produce(data, (draftData) => {
                draftData.lead.type = 'constructeur';
                draftData.lead.source = 'manufacturer';
                draftData.lead.origin = 'operator';
            }),
        success: () => {
            toast({
                variant: 'success',
                message: Intl.formatMessage({ id: 'TRANSLATOR.SUCCESS' }),
            });
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        },
    });

    const handleClick = (action: string = undefined) => {
        switch (action) {
            case 'LEAD_MANUFACTURER':
                if (Object.keys(userData.dealerships).length > 1) {
                    dispatch(actions.modalChange('manufacturer'));
                } else {
                    createAction();
                }
                break;
            case 'DEALERSHIP_VISIT':
                dispatch(actions.modalChange('visit'));
                break;
            case 'CALL':
                dispatch(actions.modalChange('call'));
                break;
            case 'SCHEDULE_APPOINTMENT':
                dispatch(actions.modalChange('appointment'));
                break;
            case 'CALLBACK':
                dispatch(actions.modalChange('callback'));
                break;
            case 'DECLARE_PURCHASE_ORDER':
                dispatch(actions.modalChange('purchase'));
                break;
            case 'SEND_EMAIL':
                dispatch(actions.modalChange('mail'));
                break;
            case 'LOCAL_COMMERCE':
                dispatch(actions.modalChange('local_commerce'));
                break;
            case 'LOCAL_APV':
                dispatch(actions.modalChange('local_apv'));
                break;
            case 'APV_IN_STORE':
                dispatch(actions.modalChange('apv_in_store'));
                break;
            case 'LOCAL_HOME_CHARGE':
                dispatch(actions.modalChange('local_home_charge'));
                break;
            case 'CONTRACT_SECURYCAR':
                dispatch(actions.modalChange('contract_securycar'));
                break;
            case 'APV_IN_STORE':
                dispatch(actions.modalChange('apv_in_store'));
                break;
            default:
                break;
        }
    };

    return (
        <>
            {loading &&
                ReactDOM.createPortal(
                    <Loader
                        style={{ width: '5rem', height: '5rem' }}
                        className="loading_manufacturer"
                        wrapperStyle={{ marginTop: '-15px' }}
                        overlay
                    />,
                    ktContent,
                )}
            {hasAnyTypologies(leadLocalTypes) && (
                <AnimateSharedLayout>
                    <motion.div
                        variants={container}
                        initial="hidden"
                        animate="visible"
                        className="fullheadband__part fullheadband__actions col-12 col-md-8"
                    >
                        {headBandActions.map(([action, properties]) => (
                            <ActionButton
                                key={action.toLowerCase()}
                                {...properties}
                                onClick={() => handleClick(action)}
                                view="list"
                            />
                        ))}
                    </motion.div>
                </AnimateSharedLayout>
            )}
        </>
    );
};

export default ActionsHandBand;
