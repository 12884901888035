import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

export const mapEventDetail = (event) => {
    const eventData = {
        informations: {
            identifier: event?.leadId || event?.eventId,
            leadType: event?.leadTypeName || event?.eventTypeName,
            leadTypeSlug: event?.eventTypeSlug,
            status: event?.leadStatusName,
            statusSlug: event?.leadStatusSlug,
            subStatus: event?.leadStatusSubName,
            leadClosLibel: event?.leadClosLibel,
            contactType: event?.leadSubject,
            price: event?.leadVehPrice || event?.sellingPriceWithTaxes || event?.interventionPriceWithTaxes,
            description: event?.description,
            dealership: {
                name: event?.leadDealershipName,
            },
            source: event?.srcApplicationName,
            billType: event?.processCategory,
            created: event?.eventCreated ? dayjs(event?.eventCreated).format('DD/MM/YYYY - HH [h] mm') : null,
            processType: event?.processType,
            document: event?.docType,
            emailAccountAfr: event?.afrSubscriberEmail,
            refDealershipId: event?.refDealershipId,
        },
        project: [],
        appointment: [],
        securycar: [],
        fullWeb: {
            orderwebAccessoires: event?.orderwebAccessoires,
            orderwebAcompte: event?.orderwebAcompte,
            orderwebAcompteDate: event?.orderwebAcompteDate,
            orderwebAcompteMode: event?.orderwebAcompteMode,
            orderwebAcompteValidBy: event?.orderwebAcompteValidBy,
            orderwebAnnonceurbasecodepvo: event?.orderwebAnnonceurbasecodepvo,
            orderwebApport: event?.orderwebApport,
            orderwebApportDate: event?.orderwebApportDate,
            orderwebApportValidBy: event?.orderwebApportValidBy,
            orderwebAutosphereId: event?.orderwebAutosphereId,
            orderwebCartegriseTarif: event?.orderwebCartegriseTarif,
            orderwebClientId: event?.orderwebClientId,
            orderwebDateCancel: event?.orderwebDateCancel,
            orderwebDateCreate: event?.orderwebDateCreate,
            orderwebDateMaj: event?.orderwebDateMaj,
            orderwebDealerId: event?.orderwebDealerId,
            orderwebDiscount: event?.orderwebDiscount,
            orderwebFinancement: event?.orderwebFinancement,
            orderwebFinancementMode: event?.orderwebFinancementMode,
            orderwebFinancementRefus: event?.orderwebFinancementRefus,
            orderwebFinancementSigned: event?.orderwebFinancementSigned,
            orderwebFinancementValid: event?.orderwebFinancementValid,
            orderwebGravage: event?.orderwebGravage,
            orderwebGravagePac: event?.orderwebGravagePac,
            orderwebItesoftId: event?.orderwebItesoftId,
            orderwebLivraison: event?.orderwebLivraison,
            orderwebLivraisonDefault: event?.orderwebLivraisonDefault,
            orderwebLivraisonId: event?.orderwebLivraisonId,
            orderwebLivraisonXInitiale: event?.orderwebLivraisonXInitiale,
            orderwebMarquageId: event?.orderwebMarquageId,
            orderwebOrderCode: event?.orderwebOrderCode,
            orderwebOrderId: event?.orderwebOrderId,
            orderwebOrigin: event?.orderwebOrigin,
            orderwebPayment: event?.orderwebPayment,
            orderwebPrice: event?.orderwebPrice,
            orderwebPvoproduit: event?.orderwebPvoproduit,
            orderwebPvoschema: event?.orderwebPvoschema,
            orderwebRefdealershipid: event?.orderwebRefdealershipid,
            orderwebRefund: event?.orderwebRefund,
            orderwebReprise: event?.orderwebReprise,
            orderwebSiteid: event?.orderwebSiteid,
            orderwebSolde: event?.orderwebSolde,
            orderwebSoldeDate: event?.orderwebSoldeDate,
            orderwebSoldeValidBy: event?.orderwebSoldeValidBy,
            orderwebStatus: event?.orderwebStatus,
            orderwebStatusId: event?.orderwebStatusId,
            orderwebStockIdentifiant: event?.orderwebStockIdentifiant,
            orderwebTotal: event?.orderwebTotal,
            orderwebVNumpoli: event?.orderwebVNumpoli,
            orderwebVehiculeidentifiant: event?.orderwebVehiculeidentifiant,
            orderwebXInitiale: event?.orderwebXInitiale,
            orderwebContact: event?.originContact,
            orderwebStatusDescription: event?.orderwebStatusDescription,
            orderwebStatusLibelle: event?.orderwebStatusLibelle,
        },
        funding: {
            fundingProcessLabel: event?.eventName,
            fundingProductType: event?.fundingProductType,
            fundingCategory: event?.processCategory,
            fundingPartnerName: event?.fundingPartnerName,
            fundingPartnerCode: event?.fundingPartnerCode,
            fundingContractNumber: event?.fundingContractNumber,
            fundingDuration: event?.fundingDuration,
            fundingPrice: event?.fundingPrice,
            fundingPrestation: event?.fundingPrestation,
            fundingStatus: event?.fundingStatus,
            fundingSellerId: event?.fundingSellerId,
            fundingSellerLastName: event?.fundingSellerLastName,
            fundingSellerFirstName: event?.fundingSellerFirstName,
            fundingEligibleProduction: event?.fundingEligibleProduction,
            fundingTegRate: event?.fundingTegRate,
            fundingFinanciallyAccounted: event?.fundingFinanciallyAccounted,
            fundingPrestations: event?.fundingPrestations,
            refDealershipLegalname: event?.refDealershipLegalname,
            fundingCreatedDate: event?.eventCreated,
        },
        icare: {
            maintenanceContractNomCommercialFormule: event?.maintenanceContractNomCommercialFormule,
            maintenanceSrcApplicationName: event?.srcApplicationName,
            maintenanceContractNumeroContract: event?.maintenanceContractNumeroContract,
            maintenantSrcProcessMoment: event?.eventCreated,
            maintenanceContractDureeCouvertureContract: event?.maintenanceContractDureeCouvertureContract,
            maintenanceContractStatutContract: event?.maintenanceContractStatutContract,
            maintenanceContractIdentifiantTiersDistributeur: event?.maintenanceContractIdentifiantTiersDistributeur,
            maintenanceRefDealershipLegalname: event?.refDealershipLegalname,
            maintenanceContractPrenomCommercialPerso: event?.maintenanceContractPrenomCommercialPerso,
            maintenanceContractNomCommercialPerso: event?.maintenanceContractNomCommercialPerso,
            maintenanceContractCodeVendeurContract: event?.maintenanceContractCodeVendeurContract,
        },
        tscan: {
            tscanId: event?.tscanId,
            positionAxle: event?.positionAxle,
            positionIndex: event?.positionIndex,
            recommandation: event?.recommandation,
            fileLink: event?.fileLink,
            tires: event?.roues,
        },
        prestationApvDMS: {
            tiNumber: event?.tiNumber,
            userInfo: event?.userInfo,
            interventionStartDate:
                isEmpty(event?.interventionStartDate) || event?.interventionStartDate === '01/01/1900'
                    ? null
                    : event?.interventionStartDate,
            interventionEndDate:
                isEmpty(event?.interventionEndDate) || event?.interventionEndDate === '01/01/1900'
                    ? null
                    : event?.interventionEndDate,
            review: event?.review,
            interventionPriceWorkforce: event?.interventionPriceWorkforce,
            interventionPricePiece: event?.interventionPricePiece,
            interventionPriceWithTaxes: event?.interventionPriceWithTaxes,
            taxes: event?.taxes,
            paymentMode: event?.paymentMode,
            paymentType: event?.paymentType,
            dealership: event?.dealership,
            lastMileageKnown: event?.lastMileageKnown,
        },
        mecaplanning: {
            processLabel: event?.eventName,
            srcProcessMoment: event?.eventCreated,
            refDealershipId: event?.refDealershipId,
            refDealershipLegalname: event?.refDealershipLegalname,
            workfilerdvid: event?.workfilerdvid,
            workfilebookedonline: event?.workfilebookedonline,
            workfilestart: event?.workfilestart,
            workfilefinish: event?.workfilefinish,
            workfilemainwork: event?.workfilemainwork,
            workfilecomment: event?.workfilecomment,
            workfileloaner: event?.workfileloaner,
            workshopid: event?.workshopid,
            workshopname: event?.workshopname,
            receivingid: event?.receivingid,
            receivingname: event?.receivingname,
        },
        fleetback: {
            auditNameModel: event?.auditNameModel,
            auditDescriptionModel: event?.auditDescriptionModel,
            auditReference: event?.auditReference,
            auditStatusIntervention: event?.auditStatusIntervention,
            auditLinkAudit: event?.auditLinkAudit,
            auditIdIntervention: event?.auditIdIntervention,
            auditPreview: event?.auditPreview,
            auditCreatedbyId: event?.auditCreatedbyId,
            auditCreatedbyEmail: event?.auditCreatedbyEmail,
            auditCreatedbyFirstname: event?.auditCreatedbyFirstname,
            auditCreatedbyLastname: event?.auditCreatedbyLastname,
        },
        cirano: {
            nameGtie: event?.nameGtie,
            typeGite: event?.typeGite,
            stateGite: event?.stateGite,
            startDate: event?.startDate,
            endDate: event?.endDate,
            pdvContributor: event?.pdvContributor,
            eventName: event?.eventName,
            srcProcessId: event?.srcProcessId,
            libFamily: event?.libFamily,
            libProduct: event?.libProduct,
        },
        adictiz: {
            dotation: event?.dotation,
            validationDuration: event?.validationDuration,
            marketingCampaignName: event?.marketingCampaignName,
        },
        opteven: {
            guarantyStatus: event?.guarantyStatus,
            referenceExtern: event?.referenceExtern,
            program: event?.program,
            numberGuarantee: event?.numberGuarantee,
            product: event?.product,
            srcProcessId: event?.srcProcessId,
        },
        commercialProposal: {
            order: event?.commercialProposal?.order,
            id: event?.commercialProposal?.id,
            number: event?.commercialProposal?.number,
            status: event?.commercialProposal?.status,
            startDate: event?.commercialProposal?.startDate ? dayjs(event?.commercialProposal?.startDate) : null,
            endDate: event?.commercialProposal?.endDate ? dayjs(event?.commercialProposal?.endDate) : null,
            affectedUserLabel: event?.commercialProposal?.affectedUserLabel,
            comment: event?.commercialProposal?.comment,
            acceptanceDate: event?.commercialProposal?.acceptanceDate
                ? dayjs(event?.commercialProposal?.acceptanceDate)
                : null,
            cancellationDate: event?.commercialProposal?.cancellationDate
                ? dayjs(event?.commercialProposal?.cancellationDate)
                : null,
            cancellationCause: event?.commercialProposal?.cancellationCause,
            price: event?.commercialProposal?.price,
            closedDate: event?.commercialProposal?.closedDate ? dayjs(event?.commercialProposal?.closedDate) : null,
            orderDeliveryDate: event?.commercialProposal?.orderDeliveryDate
                ? dayjs(event?.commercialProposal?.orderDeliveryDate)
                : null,
            upsales: event?.commercialProposal?.upsales,
        },
    };
    eventData.project.customerProject = null;
    eventData.project.vehicles = [
        {
            projectComment: null,
            registration: event?.registrationNumber,
            circulationDate: event?.circulationDate
                ? dayjs(event?.circulationDate, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD')
                : null,
            price: event?.sellingPrice,
            version: event?.version,
            make: event?.brand,
            model: event?.model,
            mileage: event?.lastMileageKnown,
            fuelType: event?.energy,
            year: event?.circulationDate
                ? dayjs(event?.circulationDate, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('YYYY')
                : null,
            color: event?.color,
            vin: event?.vin,
            registrationNumberDate: event?.registrationNumberDate,
            lastMileageKnow: event?.lastMileageKnown,
            type: event?.bodyType,
        },
    ];
    eventData.appointment.details = {
        appointmentAppointmentDateStart: event?.appointmentAppointmentDateStart,
        comment: event?.appointmentComment,
        appointmentServices: event?.appointmentServices,
        appointmentTotalOrderAmount: event?.appointmentTotalOrderAmount,
    };
    eventData.securycar.resume = [
        {
            contractId: event?.scServiceResumeContractId,
            contrat: event?.scServiceResumeProductLabel,
            startDate: event?.scServiceResumeGuaranteeStartDate,
            endDate: event?.scServiceResumeGuaranteeEndDate,
            paymentMode: event?.scServiceResumePaymentMode,
            subscriptionDate: event?.scServiceResumeSubscriptionDate,
            price: event?.scServiceResumeSellingPriceWithTaxes,
            leadFollowby:
                event?.scServiceResumeSellerFirstName && event?.scServiceResumeSellerLastName
                    ? `${event?.scServiceResumeSellerFirstName} ${event?.scServiceResumeSellerLastName}`
                    : '-',
            source: event?.scServiceResumeSource,
            contratNumber: `${event?.scServiceResumeProductCode}.${event?.scServiceResumeContractNumber}`,
        },
    ];
    eventData.securycar.disaster = [
        {
            contractId: event?.scDisasterContractId,
            type: event?.scDisasterDisasterType,
            isSupported: event?.scDisasterIsSupported,
            supportedAmountWithTaxes: event?.scDisasterSupportedAmountWithTaxes,
            disasterDate: event?.scDisasterDisasterDate,
            declarationDate: event?.scDisasterDisasterDeclarationDate,
            closureDate: event?.scDisasterDisasterClosureDate,
            insuranceCompany: event?.scDisasterInsuranceCompany,
            guaranteeEndDate: event?.scDisasterGuaranteeEndDate,
            source: event?.scDisasterSource,
            contratNumber: `${event?.scDisasterProductCode}.${event?.scDisasterDisasterNumber}`,
        },
    ];
    eventData.securycar.renewal = [
        {
            contractId: event?.scRenewalContractId,
            startDate: event?.scRenewalGuaranteeStartDate,
            endDate: event?.scRenewalGuaranteeEndDate,
            paymentMode: event?.scRenewalPaymentMode,
            subscriptionDate: event?.scRenewalSubscriptionDate,
            contractNumber: `${event?.scRenewalProductCode}.${event?.scRenewalContractNumber}`,
            source: event?.scRenewalSource,
        },
    ];
    eventData.securycar.termination = [
        {
            contractId: event?.scTerminationContractId,
            contractNumber: `${event?.scTerminationProductCode}.${event?.scTerminationContractNumber}`,
            terminationDate: event?.scTerminationTerminationDate,
            reasonSimple: event?.scTerminationTerminationReasonSimple,
        },
    ];
    return eventData;
};

export const mapAddPartExchange = (data) => ({
    partExchangeReference: data?.reference,
    make: data?.partExchangeVehicle?.make,
    model: data?.partExchangeVehicle?.model,
    version: data?.partExchangeVehicle?.version,
    dateFirstRegistration: dayjs(data?.partExchangeVehicle?.dateFirstRegistered).format('YYYY-MM'),
    fuelType: data?.partExchangeVehicle?.fuelType,
    registration: data?.partExchangeVehicle?.registration,
    mileage: data?.partExchangeVehicle?.mileage,
    estimatedPrice: data?.estimatedPrice,
    priceRebate: null,
    comment: data?.comment ?? '',
    image: data?.partExchangeVehicle?.imagePath,
});
