import { Column } from 'primereact/column';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Sorts } from '@app/hooks/useTableHandlers';

import { ApiResponse } from '@app/helpers/AxiosHelper';

import {
    ApplicationParams,
    ApplicationQueryFilter,
    Permission,
    PermissionRole,
    PermissionWithGrants,
} from '@app/crud/autouser/application.types';

import { removePermission } from '@app/store/autouser/permission/permission.thunk';
import { actions as modalActions } from '@app/store/modal/modal.store';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import SwitchInput from '@app/partials/content/SwitchInput';

type ApplicationPermissionDataTableProps = {
    start: string | number;
    filters: ApplicationParams;
    sortField: string;
    sortOrder: string;
    data: ApiResponse<Permission[]> | null;
    loading: boolean;
    handleFilter: (args: ApplicationQueryFilter) => void;
    handleSort: (e: Sorts) => void;
    refresh: () => void;
};

type ApplicationPermissionDataTableParams = {
    id: string;
};

const ApplicationPermissionDataTable = ({
    start,
    filters,
    sortField,
    sortOrder,
    data,
    loading,
    handleFilter,
    handleSort,
    refresh,
}: ApplicationPermissionDataTableProps) => {
    const Intl = useIntl();
    const { id } = useParams<ApplicationPermissionDataTableParams>();
    const applicationPermissions = data.result || [];
    const cols = data?.count ? data.result[0] : ([] as Permission[]);
    const dispatch = useDispatch();

    const categoryBodyTemplate = (applicationPermission: PermissionWithGrants) => (
        <div className="d-flex flex-column">
            <span>{applicationPermission.category}</span>
        </div>
    );

    const roleBodyTemplate = (applicationPermission: PermissionWithGrants, col: { field?: string }) => {
        if (!applicationPermission?.[col.field]) return;
        const checked = !!applicationPermission[col.field].enabled;
        const roleId = applicationPermission[col.field].id;
        const permissionId = applicationPermission.id;
        const key = `${permissionId}_${roleId}`;
        return (
            <div className="d-flex flex-column align-items-center">
                <SwitchInput name={key} active={checked} labelFirst="" labelSecond="" />
            </div>
        );
    };

    const titleBodyTemplate = (applicationPermission: PermissionWithGrants) => (
        <div className="d-flex flex-column">
            <p>
                <span className="font-weight-bold">{applicationPermission.title}</span>{' '}
                {applicationPermission.permission && (
                    <OverlayTooltip
                        id={`${applicationPermission.id}`}
                        label={applicationPermission.permission}
                        position="top"
                        translate={false}
                    >
                        <i className="las la-info-circle" />
                    </OverlayTooltip>
                )}
            </p>
            <p>
                <span className="font-weight-light" style={{ fontSize: '10px' }}>
                    {applicationPermission.code}
                </span>
            </p>
        </div>
    );

    const onClickDeletePermission = (permId: number, code: string, tableHandlerRefresh: () => void) => {
        dispatch(removePermission(permId, code, tableHandlerRefresh));
    };

    const onClickEditPermission = (permission: PermissionWithGrants) => {
        dispatch(
            modalActions.modalChange('show_permission', {
                id,
                permission,
            }),
        );
    };

    const actionsBodyTemplate = (applicationPermission: PermissionWithGrants) => (
        <div className="d-flex">
            <Button
                variant="outline-primary"
                className="mx-1"
                onClick={() => onClickEditPermission(applicationPermission)}
            >
                <i className="la la-pen p-0" />
            </Button>
            <Button
                variant="danger"
                className="mx-1"
                onClick={() => onClickDeletePermission(applicationPermission.id, applicationPermission.code, refresh)}
            >
                <i className="la la-trash-alt p-0" />
            </Button>
        </div>
    );

    const dynamicColumns = useMemo(
        () =>
            Object.values(cols)
                .filter((role: PermissionRole) => !!role?.name)
                .map((role: PermissionRole, index) => {
                    const className = `grant-${index}`;
                    return (
                        <Column
                            className={className}
                            field={className}
                            header={role.name}
                            style={{ width: '100px' }}
                            body={roleBodyTemplate}
                        />
                    );
                }),
        [cols],
    );

    return (
        <FiltersProvider value={filters}>
            {/* @ts-ignore */}
            <HoCDataTable
                value={applicationPermissions}
                totalRecords={data?.count}
                rows="100"
                start={start}
                first={start}
                loading={loading}
                rowGroupMode="rowspan"
                groupRowsBy="category"
                onFilter={handleFilter}
                sortField={sortField}
                sortOrder={sortOrder}
                onSort={handleSort}
                currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                removableSort
                className="overflow-auto"
                emptyMessage={Intl.messages['AUTOUSER.APPLICATION.DATATABLE.EMPTY_RESULT']}
            >
                <Column
                    field="category"
                    header={Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.TABLE.CATEGORY' })}
                    style={{ width: '100px' }}
                    body={categoryBodyTemplate}
                    sortable
                />
                <Column
                    field="title"
                    header={Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.TABLE.TITLE' })}
                    style={{ width: '200px' }}
                    body={titleBodyTemplate}
                    sortable
                />
                {dynamicColumns}
                <Column
                    field="actions"
                    header={Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.TABLE.ACTIONS' })}
                    style={{ width: '130px' }}
                    body={actionsBodyTemplate}
                />
            </HoCDataTable>
        </FiltersProvider>
    );
};

export default ApplicationPermissionDataTable;
