import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import {
    acceptVehiclePriceRecommendation,
    getPricingReasonsByStatusSlug,
    modifyVehiclePriceModification,
    PriceModificationProps,
} from '@app/crud/stock/stock.crud';

import { useAppDispatch, useAppSelector, useFetch } from '@app/hooks';
import { actions } from '@app/store/stock/stock.store';
import { Vehicle } from '@app/store/stock/stock.type';
import type { EnabledCommentType } from '@app/store/stock/stock.type';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import { getPublishDays } from '@app/partials/content/Vehicle/VehicleHelpers';
import ErrorForm from '@app/partials/layout/ErrorForm';

import Price from '@app/components/formatters/price/Price';

import StockPriceLeadsButton from '../../partials/price/StockPriceLeadsButton';
import { addProcessingPricing } from '../todo/helpers/ProcessingPricing';

type ModifyModalProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    refresh: () => void;
};

const ModifyModal = ({ showModal, setShowModal, refresh }: ModifyModalProps) => {
    const Intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [internalReason, setInternalReason] = useState(null);
    const [internalComment, setInternalComment] = useState('');
    const [reasons, setReasons] = useState(null);
    const commentRequiredLength = 10;
    const warning = false;
    const { vehiclesSelected } = useAppSelector((state) => state.stock);
    const dispatch = useAppDispatch();

    const { params } = useAppSelector((state) => state.modal);
    const vehicles = params?.vehicles;
    const checkMode = params?.checkMode;
    const multiple = vehicles && Object.keys(vehicles).length > 1;
    const licenseNumber = params?.licenseNumber;
    const confirmMethod = checkMode ? modifyVehiclePriceModification : acceptVehiclePriceRecommendation;
    const [enabledComment, setEnabledComment] = useState<EnabledCommentType>({});

    const { control, register, handleSubmit, errors, getValues, setValue, watch } = useForm({
        shouldUnregister: true,
    });

    useFieldArray({
        control,
        name: 'pricing',
    });

    const {
        fetch: fetchReasons,
        data,
        loading: reasonsLoading,
    } = useFetch({
        fetchAction: getPricingReasonsByStatusSlug,
        resultInterceptor: (response) => response,
    });

    const closeModal = () => {
        setShowModal(false);
        setEnabledComment({});
    };

    useEffect(() => {
        fetchReasons('modified');
    }, [fetchReasons]);

    useEffect(() => {
        if (Array.isArray(vehiclesSelected) && vehiclesSelected.length > 0)
            Object.values(vehiclesSelected).filter((item: any) => item.newPrice !== undefined);
    }, [vehiclesSelected]);

    const onConfirm = (formData: { pricing: PriceModificationProps[] }) => {
        const values = Object.entries(formData.pricing).filter(([_, item]) => item.newPrice !== undefined);

        const filteredPrices = {};

        values.forEach(([key, item]) => {
            // @ts-ignore
            filteredPrices[key] = item;
        });

        setLoading(true);
        confirmMethod(formData.pricing)
            .then((response) => {
                if (response?.statusCode === 400 || response?.statusCode === 500) {
                    if (response?.error) {
                        toast({
                            variant: 'danger',
                            message: response.error,
                        });
                        return;
                    }
                    throw new Error('Error with statusCode 400 or 500');
                }

                if (refresh) refresh();
                dispatch(actions.UpdateVehicleSelected([]));
                setShowModal(false);

                addProcessingPricing(Object.keys(filteredPrices));
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.CONFIRM.REASON.CONFIRMED' }),
                });
            })
            .catch(() => {
                if (refresh) refresh();
                dispatch(actions.UpdateVehicleSelected([]));
                setShowModal(false);

                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.CONFIRM.REASON.FAILED' }),
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (data.result) {
            setReasons(
                // @ts-ignore
                data?.result?.map((item: { id: string; description: string }) => ({
                    label: item.id,
                    value: item.description,
                })),
            );
        }
    }, [data]);

    const changeReason = (e: React.ChangeEvent<HTMLInputElement>, sku: string) => {
        setInternalReason(Number(e.target.value));
        setInternalComment('');

        if (data.result) {
            const otherReason = data.result?.find((reason) => reason.description === 'Autre');
            if (otherReason && otherReason.id === Number(e.target.value)) {
                setEnabledComment({ ...enabledComment, ...{ [sku]: true } });
            } else {
                setEnabledComment({ ...enabledComment, ...{ [sku]: false } });
            }
        }
    };

    const validatePrice = (value: number, vehiclePrice: number) => {
        const regex = new RegExp(`^[0-9]+99$`);

        if (!regex.test(String(value))) {
            return Intl.formatMessage({ id: 'STOCK.PRICING.MODAL.MODIFY.ERROR_FORMAT' });
        }

        return value && value !== vehiclePrice
            ? true
            : Intl.formatMessage({ id: 'STOCK.PRICING.MODAL.MODIFY.MULTIPLE.SAME_PRICE' });
    };

    const rowExpansionTemplate = (col: any) => (
        <div className="d-flex" id={col.sku}>
            <Form.Group className="mt-2 pr-5" style={{ width: '40%' }}>
                <Form.Label>
                    <FormattedMessage id="STOCK.PRICING.HISTORY.REASON" />
                </Form.Label>
                <Form.Control
                    as="select"
                    defaultValue={-1}
                    name={`pricing[${col.sku}].reasonId`}
                    ref={register({
                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                        validate: (value) =>
                            value !== '-1' ? true : Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    })}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        changeReason(e, col.sku);
                    }}
                >
                    <option value="-1" disabled>
                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.PLACEHOLDER' })}
                    </option>
                    {reasons?.map((reason: any) => (
                        <option key={`reason_${reason.label}`} value={reason.label}>
                            {reason.value}
                        </option>
                    ))}
                </Form.Control>
                <ErrorForm errors={errors} name={`pricing[${col.sku}].reasonId`} />
            </Form.Group>
            {enabledComment[col.sku] && (
                <Form.Group className="mt-2 pl-5" style={{ width: '60%' }}>
                    <Form.Label>
                        <FormattedMessage id="TRANSLATOR.COMMENT" />
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        name={`pricing[${col.sku}].comment`}
                        defaultValue=""
                        minLength={commentRequiredLength}
                        // @ts-ignore
                        ref={register({
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            validate: (value) =>
                                value.length >= commentRequiredLength ? (
                                    true
                                ) : (
                                    <FormattedMessage id="STOCK.PRICING.MODAL.MODIFY.MULTIPLE.COMMENT_TOO_SHORT" />
                                ),
                        })}
                    />
                    <ErrorForm errors={errors} name={`pricing[${col.sku}].comment`} />
                    <p className="mt-1 mb-0">
                        <FormattedHTMLMessage
                            id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.PRECISE.REQUIRED"
                            values={{
                                caracters: parseInt(watch(`pricing[${col.sku}].comment`)?.length, 10) || 0,
                                requiredLength: commentRequiredLength,
                                color:
                                    watch(`pricing[${col.sku}].comment`)?.length >= commentRequiredLength
                                        ? 'success'
                                        : 'danger',
                            }}
                        />
                    </p>
                </Form.Group>
            )}
        </div>
    );

    const cols = [
        {
            field: 'vehicle',
            header: <FormattedMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.VEHICLE" />,
            body: (vehicle: Vehicle) => (
                <>
                    <strong>{vehicle.license_number}</strong>
                    <br />
                    {vehicle.brand_model}
                </>
            ),
        },
        {
            field: 'price',
            header: <FormattedMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.PRICE" />,
            body: (vehicle: Vehicle) => (
                <strong>
                    <Price value={vehicle.price} />
                </strong>
            ),
        },
        {
            field: 'days_in_stock',
            header: <FormattedHTMLMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.DAYS_IN_STOCK" />,
            body: (vehicle: Vehicle) => (
                <>
                    {dayjs().diff(dayjs(vehicle.date_in_stock).format('YYYY-MM-DD'), 'day')}{' '}
                    <FormattedMessage id="TRANSLATOR.DAYS" />
                </>
            ),
        },
        {
            field: 'days_published',
            header: <FormattedHTMLMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.DAYS_PUBLISHED" />,
            body: (vehicle: Vehicle) => (
                <>
                    {getPublishDays(vehicle)} <FormattedMessage id="TRANSLATOR.DAYS" />
                </>
            ),
        },
        {
            field: 'leads',
            header: <FormattedHTMLMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.LEADS" />,
            body: (vehicle: Vehicle) => (
                <StockPriceLeadsButton
                    vehicleRegistration={vehicle.license_number}
                    variant="primary"
                    icon
                    label={<FormattedMessage id="STOCK.PRICING.BUTTON.LEADS" />}
                    className="ml-2"
                />
            ),
        },
        {
            field: 'spot_price',
            header: <FormattedMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.SPOT_PRICE" />,
            body: (vehicle: Vehicle) => (
                <Price value={vehicle.pricing.spotPrice} thousandSeparator=" " displayType="text" suffix=" €" />
            ),
        },
        {
            field: 'average_age',
            header: <FormattedHTMLMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.AVERAGE_AGE" />,
            body: (vehicle: Vehicle) => (
                <>
                    {vehicle.pricing.averageSellingTime} <FormattedMessage id="TRANSLATOR.DAYS" />
                </>
            ),
        },
        {
            field: 'reco_price',
            header: <FormattedHTMLMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.RECO_PRICE" />,
            body: (vehicle: Vehicle) => (
                <strong className="text-primary">
                    <Price value={vehicle.pricing.recoPrice} thousandSeparator=" " displayType="text" suffix=" €" />
                </strong>
            ),
        },
        {
            field: 'new_price',
            header: <FormattedHTMLMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.NEW_PRICE" />,
            body: (vehicle: Vehicle) => (
                <>
                    <Controller
                        name={`pricing[${vehicle.sku}].newPrice`}
                        control={control}
                        defaultValue="0"
                        rules={{
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            validate: (value) => validatePrice(value, vehicle.price),
                        }}
                        render={(props) => (
                            <Price
                                customInput={InputText}
                                suffix=" €"
                                value={props.value}
                                onValueChange={(e) => {
                                    setValue(`pricing[${vehicle.sku}].newPrice`, parseInt(e.value, 10));
                                }}
                                displayType="input"
                                className="text-center"
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    borderColor: '#E6E6E6',
                                    color: '#525672',
                                }}
                            />
                        )}
                    />
                    <ErrorForm errors={errors} name={`pricing[${vehicle.sku}].newPrice`} />
                </>
            ),
        },
        {
            field: 'delete',
            header: '',
            // @ts-ignore
            body: (vehicle: Vehicle) => <i className="las la-times" onClick={() => deleteRow(vehicle.sku)} />,
        },
    ];

    const deleteRow = async (sku: string) => {
        await dispatch(actions.DeleteVehicleSelectedItem(sku));

        Object.values(vehiclesSelected).filter((item: any) => item.newPrice !== undefined);
    };

    const dynamicColumns = cols.map((col) => col && <Column key={col.field} {...col} />);

    return (
        <>
            {vehicles && (
                <ModalDefault
                    show={showModal}
                    loading={loading || reasonsLoading}
                    hideModal={() => closeModal()}
                    className={`${multiple && 'modal--fullwidth'}`}
                    icon={
                        multiple ? (
                            <i className="text-primary las la-money-bill-wave fa-2x" />
                        ) : (
                            <i className="text-primary las la-edit fa-2x" />
                        )
                    }
                    title={
                        <span className="text-primary">
                            {multiple ? (
                                <FormattedHTMLMessage id="STOCK.PRICING.MODAL.MODIFY.MULTIPLE.TITLE" />
                            ) : checkMode ? (
                                <FormattedHTMLMessage id="STOCK_VEHICLES.MODAL.PRICE.MODIFY.CHECK.TITLE" />
                            ) : (
                                <FormattedMessage id="STOCK.PRICING.MODAL.MODIFY.TITLE" />
                            )}
                            {licenseNumber && (
                                <>
                                    <br />
                                    <strong>{licenseNumber}</strong>
                                </>
                            )}
                        </span>
                    }
                    body={
                        multiple ? (
                            <div className="pricing d-flex flex-column align-items-center h-100">
                                <p className="text-center">
                                    <FormattedHTMLMessage
                                        id="STOCK.PRICING.MODAL.MODIFY.TEXT"
                                        values={{ vehicles: Object.keys(vehiclesSelected).length }}
                                    />
                                </p>
                                {vehiclesSelected && (
                                    // @ts-ignore
                                    <DataTable
                                        value={Object.values(vehiclesSelected)}
                                        // @ts-ignore
                                        expandedRows={Object.values(vehiclesSelected)}
                                        rowExpansionTemplate={rowExpansionTemplate}
                                        scrollable
                                        scrollHeight="55vh"
                                        className="w-100"
                                    >
                                        {dynamicColumns}
                                    </DataTable>
                                )}
                            </div>
                        ) : (
                            <div className="d-flex flex-column align-items-center h-100" style={{ minHeight: '10vh' }}>
                                <div className="d-flex gap-10 mb-5">
                                    <span>
                                        <FormattedMessage id="STOCK.PRICING.MODAL.MODIFY.ACTUAL_PRICE" />
                                        <strong>
                                            <Price value={vehicles[Object.keys(vehicles)[0]]?.price} />
                                        </strong>
                                    </span>
                                    <span>
                                        <FormattedMessage id="STOCK.PRICING.MODAL.MODIFY.RECO_PRICE" />
                                        <strong>
                                            <Price value={vehicles[Object.keys(vehicles)[0]]?.newPrice} />
                                        </strong>
                                    </span>
                                </div>
                                <div>
                                    <Form.Group>
                                        <Controller
                                            name={`pricing[${Object.keys(vehicles)[0]}].newPrice`}
                                            control={control}
                                            rules={{
                                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                                pattern: {
                                                    value: new RegExp(`^[0-9]+99$`),
                                                    message: Intl.formatMessage({
                                                        id: 'STOCK.PRICING.MODAL.MODIFY.ERROR_FORMAT',
                                                    }),
                                                },
                                            }}
                                            render={(props) => (
                                                <>
                                                    <div className="text-center mb-2">
                                                        <strong>
                                                            <FormattedMessage id="STOCK.PRICING.MODAL.MODIFY.NEW_PRICE" />
                                                        </strong>
                                                    </div>
                                                    <Price
                                                        customInput={InputText}
                                                        suffix=" €"
                                                        value={getValues(
                                                            `pricing[${Object.keys(vehicles)[0]}].newPrice`,
                                                        )}
                                                        onValueChange={(e) => {
                                                            props.onChange(e.value);
                                                        }}
                                                        displayType="input"
                                                        className="text-center mb-5"
                                                        style={{
                                                            fontSize: '18px',
                                                            fontWeight: 600,
                                                            borderColor: '#E6E6E6',
                                                            color: '#525672',
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                        <ErrorForm
                                            errors={errors}
                                            name={`pricing[${Object.keys(vehicles)[0]}].newPrice`}
                                        />
                                    </Form.Group>
                                </div>
                                <Form.Group className="mt-2 w-100">
                                    <Form.Label>
                                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.MODIFY.REASON.TITLE" />
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        name={`pricing[${Object.keys(vehicles)[0]}].reasonId`}
                                        ref={register}
                                        defaultValue={-1}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            changeReason(e, Object.keys(vehicles)[0]);
                                        }}
                                    >
                                        <option value={-1} disabled>
                                            {Intl.formatMessage({
                                                id: 'STOCK_VEHICLES.MODAL.PRICE.MODIFY.REASON.PLACEHOLDER',
                                            })}
                                        </option>
                                        {reasons?.map((reason: any, key: any) => (
                                            <option key={reason} value={reason.label}>
                                                {reason.value}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {enabledComment[Object.keys(vehicles)[0]] && (
                                    <Form.Group className="mt-5 w-100">
                                        <Form.Label>
                                            <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CONFIRM.REASON.TITLE" />
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name={`pricing[${Object.keys(vehicles)[0]}].comment`}
                                            ref={register}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setInternalComment(e.target.value);
                                            }}
                                        />
                                        <p className="mt-1 mb-0">
                                            <FormattedHTMLMessage
                                                id="STOCK_VEHICLES.MODAL.PRICE.CONFIRM.REASON.TITLE.REQUIRED"
                                                values={{
                                                    caracters: getValues(
                                                        `pricing[${Object.keys(vehicles)[0]}].comment`,
                                                    )?.trim().length,
                                                    requiredLength: commentRequiredLength,
                                                    color:
                                                        getValues(
                                                            `pricing[${Object.keys(vehicles)[0]}].comment`,
                                                        )?.trim().length >= commentRequiredLength
                                                            ? 'success'
                                                            : 'danger',
                                                }}
                                            />
                                        </p>
                                    </Form.Group>
                                )}
                                <p
                                    className={`${
                                        warning && 'text-dark-orange'
                                    } d-flex align-items-center order-2 w-100 mb-0 text-left`}
                                >
                                    <i
                                        className={`las la-info-circle fa-3x ${
                                            warning ? 'text-dark-orange' : 'text-dark'
                                        } mr-2`}
                                    />
                                    {warning ? (
                                        <FormattedHTMLMessage id="STOCK_VEHICLES.MODAL.PRICE.CONFIRM.TEXT_WARNING" />
                                    ) : (
                                        <FormattedHTMLMessage id="STOCK_VEHICLES.MODAL.PRICE.CONFIRM.TEXT" />
                                    )}
                                </p>
                            </div>
                        )
                    }
                    footer={
                        <div className="d-flex justify-content-center">
                            <Button className="mr-2" variant="outline-primary" onClick={() => closeModal()}>
                                <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.CANCEL" />
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleSubmit(onConfirm)}
                                disabled={
                                    !multiple &&
                                    (!internalReason ||
                                        loading ||
                                        (Object.values(enabledComment)?.some((item) => item === true) &&
                                            internalComment.trim().length < commentRequiredLength))
                                }
                            >
                                <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.CONFIRM" />
                            </Button>
                        </div>
                    }
                />
            )}
        </>
    );
};

export default ModifyModal;
