import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useContext, useMemo } from 'react';
import { Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFadeOut from '@app/hooks/useFadeOut';
import useModal from '@app/hooks/useModal';

import { routeTo } from '@app/helpers/RoutesHelper';

import { PriorityActions } from '@app/crud/dashboard/dashboard.crud';

import { useAppSelector } from '@app/hooks';
import { getLeadStatus } from '@app/pages/customers/Helpers/Lead';
import { CreateAlreadyExist } from '@app/pages/customers/Modals';
import LeadStatusRow from '@app/pages/customers/Partials/LeadStatusRow';
import { checkIdenticalData } from '@app/pages/leadsOnline/Helpers/LeadOnlineHelper';
import ROUTES from '@app/router/Routes';

import { DashboardContext } from '../../DashboardProvider';

interface DashboardPrioritiesTimelineProps {
    actions: PriorityActions[];
    picture?: boolean;
    statusWrap?: boolean;
}

const DashboardPrioritiesTimeline = ({
    actions,
    picture = true,
    statusWrap = true,
}: DashboardPrioritiesTimelineProps) => {
    const [showCreateAlreadyExistModal, toggleCreateAlreadyExistModal, createAlreadyExistParams] = useModal(
        false,
        'create_already_exist',
    );
    const { setLoading } = useContext(DashboardContext);
    const { userData } = useAppSelector((state) => state.auth);
    const { onBottom, handleScroll, fadeOutRef } = useFadeOut();

    const actionToDoForApv = ['agent-autosphere-contact', 'conseiller-commercial-services'];

    const timelines = useMemo(
        () =>
            actions.map((item) => {
                const status = getLeadStatus(item?.leadStatus?.slug);

                return (
                    <div key={item.lead.id} className="dashboard-priorities__row">
                        <div
                            className={classNames('font-weight-bold align-items-center', {
                                'd-flex': !statusWrap,
                            })}
                        >
                            <span className={`font-size-h3 line-height-0 mr-2 text-${status}`}>&bull;</span>
                            <span className="font-size-lg dashboard-priorities__type">{item?.leadType?.name}</span>
                            <LeadStatusRow
                                leadStatusSlug={item?.leadStatus?.slug}
                                leadStatusName={item?.leadStatus?.name}
                                leadStatusSubName={item?.leadStatus?.subName}
                                className="d-flex flex-nowrap align-items-center"
                                shift="ml-2"
                            />
                        </div>
                        {item?.contact?.id && (
                            <div className="mt-1 ml-5">
                                Client :{' '}
                                <strong>
                                    {item?.contact?.firstname} {item?.contact?.lastname}
                                </strong>
                            </div>
                        )}
                        <div className="ml-5 font-weight-bold">
                            Prochaine action :{' '}
                            {actionToDoForApv.includes(userData?.role.slug)
                                ? Intl.formatMessage({ id: 'DASHBOARD.ACTION.TO.DO' })
                                : item?.leadStatus?.subName}
                        </div>
                        {item?.action?.scheduleAppointment?.dateStart && (
                            <div className="ml-5">
                                {dayjs(item.action.scheduleAppointment.dateStart).format(
                                    '[le] DD/MM/YYYY [à] HH [h] mm',
                                )}
                            </div>
                        )}
                        {item?.contact?.id ? (
                            <Link
                                to={routeTo(
                                    ROUTES.CUSTOMER.PATH,
                                    {
                                        id: item?.contact?.id,
                                    },
                                    {
                                        action: 'viewLead',
                                        leadId: item?.lead?.id,
                                    },
                                )}
                                className="ml-5"
                            >
                                <Button className="mt-2" size="sm" variant="outline-primary">
                                    {Intl.formatMessage({ id: 'DASHBOARD.PRIORITIES.SHOW' })}
                                </Button>
                            </Link>
                        ) : (
                            <Button
                                className="ml-5 mt-2"
                                size="sm"
                                variant="outline-primary"
                                onClick={() => checkIdenticalData(item?.lead, false, setLoading)}
                            >
                                {Intl.formatMessage({ id: 'DASHBOARD.PRIORITIES.SHOW' })}
                            </Button>
                        )}
                    </div>
                );
            }),
        [actions],
    );

    return (
        <>
            {actions?.length > 0 ? (
                <>
                    <div className="dashboard-priorities__timeline" ref={fadeOutRef} onScroll={handleScroll}>
                        {timelines}
                    </div>
                    {!onBottom && <div className="dashboard-priorities__fadeout" />}
                </>
            ) : (
                <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                    <span className="text-center text-dark-50 font-weight-semibold font-size-lg">
                        Vous n’avez aucune
                        <br /> action à réaliser…
                    </span>
                    {picture && <Image className="mt-8" src="/media/pages/dashboard/dashboard-nuthin-to-do.svg" />}
                </div>
            )}
            <CreateAlreadyExist
                showModal={showCreateAlreadyExistModal}
                setShowModal={toggleCreateAlreadyExistModal}
                {...createAlreadyExistParams}
            />
        </>
    );
};

DashboardPrioritiesTimeline.defaultProps = {
    picture: true,
    statusWrap: true,
};

export default DashboardPrioritiesTimeline;
