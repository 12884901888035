import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import './campaign.scss';
import useFetch from '@app/hooks/useFetch';
import useFilters from '@app/hooks/useFilters';
import useModal from '@app/hooks/useModal';
import useQueryParams from '@app/hooks/useQueryParams';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { ucwords } from '@app/helpers/StringHelper';
import { empty } from '@app/helpers/ToolsHelper';

import { getCampaigns, getCampaignSettings, importCampaigns } from '@app/crud/campaigns/campaign.crud';

import { CalendarFilter } from '@app/partials/content/CalendarFilter';
import DropdownFilter from '@app/partials/content/DropdownFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import ImportCsv from '@app/partials/content/modals/ImportCsv';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';

import Campaign from './Campaign';
import CampaignProvider from './CampaignProvider';
import CampaignStatusRow from './partials/CampaignStatusRow';

const Campaigns = () => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [showImportCsv, setShowImportCsv] = useModal(false, 'import_csv');
    const queryParams = useQueryParams();
    const history = useHistory();
    const location = useLocation();
    const { items, start, filters, updateFilters, sortField, sortOrder, resetAllFilters } = useFilters({
        name: 'campaigns',
    });

    const {
        data: campaigns,
        loading,
        handleFilter,
        handlePagination,
        handleSort,
        refresh,
    } = useTableHandlers({
        filterStore: 'campaigns',
        fetchAction: getCampaigns,
        withStore: false,
    });

    const { data: campaignSettings } = useFetch({
        fetchAction: getCampaignSettings,
        autoFetch: true,
    });

    const calendarFilter = (name) => <CalendarFilter name={name} onChange={handleFilter} />;

    useEffect(() => {
        if (queryParams?.slug) {
            updateFilters({
                filters: {
                    ...filters,
                    slug: {
                        value: queryParams.slug,
                    },
                },
            });
        }
    }, [queryParams]);

    useEffect(() => {
        setExpandedRows(
            expandedRows.reduce((result, current) => {
                const campaign = campaigns?.result?.find?.((c) => c.id === current?.id);

                if (campaign) {
                    result.push(campaign);
                }
                return result;
            }, []),
        );

        if (queryParams?.slug) {
            const querySlug = queryParams?.slug;
            const campaign = campaigns?.result?.find?.((c) => c.slug === querySlug);
            if (campaign) {
                setExpandedRows([campaign]);
                history.replace(location.pathname);
            }
        }
    }, [campaigns]);

    const campaignTypes =
        campaigns?.result &&
        Object.values(campaigns?.result)
            ?.filter?.((campaign) => !!campaign?.campaignType?.slug)
            ?.map?.((campaign) => ({
                name: campaign?.campaignType?.name,
                key: campaign?.campaignType?.slug,
            }));

    const uniqueCampaignTypes = campaignTypes?.filter?.(
        (a, i) => campaignTypes.findIndex((s) => a.key === s.key) === i,
    );
    uniqueCampaignTypes?.push?.({ name: '-', key: null });

    const campaignStatus =
        campaigns?.result &&
        Object.values(campaigns?.result)
            ?.filter?.((campaign) => !!campaign?.campaignStatus?.slug)
            ?.map?.((campaign) => ({
                name: campaign?.campaignStatus?.name,
                key: campaign?.campaignStatus?.slug,
            }));
    const uniqueCampaignStatus = campaignStatus?.filter?.(
        (a, i) => campaignStatus.findIndex((s) => a.key === s.key) === i,
    );

    const selectDropdown = (name, values) => (
        <DropdownFilter optionLabel="name" filterBy="name" name={name} options={values} onChange={handleFilter} />
    );

    const statusBodyTemplate = (campaign) => (
        <>
            <CampaignStatusRow
                campaignStatusSlug={campaign?.campaignStatus?.slug}
                campaignStatusName={campaign?.campaignStatus?.name}
                campaignStatusSubName={campaign?.campaignStatus?.subName}
                className="mb-1 d-flex flex-nowrap text-nowrap align-items-center"
            />
            {dayjs(campaign?.updated).format('DD/MM/YYYY [à] HH [h] mm')}
        </>
    );

    const startBodyTemplate = (campaign) => {
        if (!campaign?.dateStart) {
            return '-';
        }
        const campaignDateStart = dayjs(campaign?.dateStart);

        return (
            <span className="text-success">
                <span className="mr-1">&bull;</span>
                {campaignDateStart.format('DD/MM/YYYY')}
                <br />
                {campaignDateStart.format('[à] HH [h] mm')}
            </span>
        );
    };

    const endBodyTemplate = (campaign) => {
        if (!campaign?.duration || !dayjs(campaign?.dateStart)?.isValid()) {
            return '-';
        }
        const campaignDateEnd = dayjs(campaign?.dateStart)
            ?.add(campaign.duration - 1, 'days')
            ?.hour(23)
            ?.minute(59)
            ?.second(59);

        return (
            <span className="text-danger">
                <span className="mr-1">&bull;</span>
                {campaignDateEnd.format('DD/MM/YYYY')}
                <br />
                {campaignDateEnd.format('[à] HH [h] mm')}
            </span>
        );
    };

    const createdBodyTemplate = (campaign) => {
        const created = dayjs(campaign?.created);

        return <span>{created.format('DD/MM/YYYY')}</span>;
    };

    const actionsBodyTemplate = (campaign) => (
        <Button
            variant="primary"
            size="sm"
            onClick={() => {
                if (!expandedRows.includes(campaign)) {
                    setExpandedRows([campaign]);
                } else {
                    setExpandedRows([]);
                }
            }}
        >
            <span className="mr-1">
                <FormattedMessage id="TRANSLATOR.SHOW" />
            </span>
            <i className={`m-0 las ${expandedRows.includes(campaign) ? 'la-minus' : 'la-plus'}`} />
        </Button>
    );

    const dealershipsBodyTemplate = (campaign) => {
        return (
            <span className="d-flex justify-content-between align-items-end">
                <span className="flex-grow-1">{campaign?.dealership?.['usualName']}</span>
            </span>
        );
    };

    const typeBodyTemplate = (campaign) => {
        if (!campaign?.campaignType) {
            return '-';
        }
        return campaign?.campaignType?.name;
    };

    const rowExpansionTemplate = (campaign) => <Campaign campaign={campaign} refresh={refresh} />;

    const expandedRowClass = (campaign) => ({
        ...(expandedRows.includes(campaign) && { 'p-datatable-row-flag--closed': true }),
    });
    const inputFilter = (name) => <InputFilter name={name} onChange={handleFilter} />;

    const cols = [
        {
            field: 'slug',
            header: Intl.formatMessage({ id: 'CAMPAIGNS.TABLE.HEAD.SLUG' }),
            filter: true,
            filterElement: inputFilter('slug'),
            style: { maxWidth: '15%', overflowWrap: 'anywhere' },
        },
        {
            field: 'name',
            header: Intl.formatMessage({ id: 'CAMPAIGNS.TABLE.HEAD.NAME' }),
            filter: true,
            filterElement: inputFilter('name'),
            style: { maxWidth: '15%', overflowWrap: 'anywhere' },
        },
        {
            field: 'campaignType',
            header: Intl.formatMessage({ id: 'CAMPAIGNS.TABLE.HEAD.TYPE' }),
            body: typeBodyTemplate,
            filter: true,
            filterElement: selectDropdown('campaignTypeSlug', uniqueCampaignTypes),
            style: { maxWidth: '10%' },
        },
        {
            field: 'dealership',
            filter: true,
            filterElement: inputFilter('dealership'),
            header: Intl.formatMessage({ id: 'CAMPAIGNS.TABLE.HEAD.DEALERSHIP' }),
            style: { maxWidth: '10%' },
            body: dealershipsBodyTemplate,
        },
        {
            field: 'dateStart',
            filter: true,
            filterElement: calendarFilter('dateStart'),
            header: Intl.formatMessage({ id: 'CAMPAIGNS.TABLE.HEAD.START' }),
            style: { maxWidth: '5%' },
            body: startBodyTemplate,
        },
        {
            field: 'duration',
            header: Intl.formatMessage({ id: 'CAMPAIGNS.TABLE.HEAD.END' }),
            style: { maxWidth: '5%' },
            body: endBodyTemplate,
        },
        {
            field: 'campaignStatus',
            header: Intl.formatMessage({ id: 'CAMPAIGNS.TABLE.HEAD.STATUS' }),
            style: { maxWidth: '10%' },
            filter: true,
            filterElement: selectDropdown('campaignStatusSlug', uniqueCampaignStatus),
            body: statusBodyTemplate,
        },
        {
            field: 'created',
            header: Intl.formatMessage({ id: 'CAMPAIGNS.TABLE.HEAD.CREATED' }),
            style: { maxWidth: '5%' },
            filter: true,
            filterElement: calendarFilter('created'),
            body: createdBodyTemplate,
        },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: 'CAMPAIGNS.TABLE.HEAD.ACTIONS' }),
            style: { maxWidth: '5%' },
            body: actionsBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    document?.getElementById('menu-campaigns')?.addEventListener('click', () => {
        refresh();
        setExpandedRows([]);
    });

    return (
        <CampaignProvider value={{ ...campaignSettings }}>
            <Card>
                <Card.Header>
                    <div className="d-flex justify-content-between col">
                        <div>
                            <Button variant="outline-secondary mr-5" onClick={() => resetAllFilters()}>
                                <FormattedMessage id="CAMPAIGNS.TABLE.RESET_FILTERS" />
                            </Button>
                            <Button
                                variant="outline-primary"
                                onClick={() => {
                                    refresh();
                                }}
                            >
                                <i className="mr-3 fas fa-sync-alt" />
                                <FormattedMessage id="TABLE.REFRESH" />
                            </Button>
                        </div>

                        <Button variant="primary" onClick={setShowImportCsv}>
                            {Intl.formatMessage({ id: 'TRANSLATOR.IMPORT' })}
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    <FiltersProvider value={filters}>
                        <HoCDataTable
                            className="campaign"
                            value={campaigns?.result}
                            totalRecords={campaigns?.facets?.totalCount}
                            lazy
                            loading={loading}
                            paginator
                            onFilter={handleFilter}
                            onPage={handlePagination}
                            onSort={handleSort}
                            paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                            currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                            rows={items}
                            first={start}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            removableSort
                            rowsPerPageOptions={[25, 50, 100]}
                            emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                            autoLayout
                            filterDisplay="row"
                            expandedRows={expandedRows}
                            rowExpansionTemplate={rowExpansionTemplate}
                            rowClassName={expandedRowClass}
                        >
                            {dynamicColumns}
                        </HoCDataTable>
                    </FiltersProvider>
                </Card.Body>
            </Card>
            <ImportCsv
                showModal={showImportCsv}
                setShowModal={setShowImportCsv}
                uploadDest={importCampaigns}
                title="CAMPAIGN.IMPORT"
                filename={false}
                prefix="CampagneSales_"
                description="CAMPAIGN.IMPORT.DESC"
                successMessage="CAMPAIGN.IMPORT.SUCCESS"
                failedMessage="CAMPAIGN.IMPORT.FAILED"
            />
        </CampaignProvider>
    );
};

export default Campaigns;
