import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { PartExchange } from '@app/crud/customers/customer.type';

import { actions } from '@app/store/modal/modal.store';

import Price from '@app/components/formatters/price/Price';

import {
    getBrandTemplate,
    getFuelTypeTemplate,
    getGearsTypeTemplate,
    getPictureTemplate,
    getProductionYearTemplate,
} from './Helpers';

type PartExchangeInfoProps = {
    partExchange?: PartExchange;
    reprise?: boolean;
    className?: string;
    event: any;
};

const PartExchangeInfo = ({ partExchange, reprise, className, event }: PartExchangeInfoProps) => {
    const dispatch = useDispatch();

    return (
        <div
            className={classNames(
                'd-flex flex-column vehicle-container h-100 kt-ribbon kt-ribbon--round kt-ribbon--right',
                className,
            )}
        >
            <img src={getPictureTemplate(partExchange)} width="100%" alt="véhicule" />
            <div className="position-relative">
                <div className=" d-flex flex-column text-center px-5 py-3 m-auto position-absolute marque-modele">
                    <span className="marque text-nowrap text-truncate">
                        {getBrandTemplate(partExchange)} {partExchange?.model || ''}
                    </span>
                    <span className="version">{partExchange ? partExchange?.version : 'Version'}</span>
                </div>
                <div className="d-flex flex-wrap justify-content-evenly mt-15 pb-4">
                    {getFuelTypeTemplate(partExchange) && (
                        <div className="d-flex w-lg-50 w-xxl-25 mt-5 flex-column align-items-center text-center">
                            <img src="/media/icons/fuel.svg" alt="fuel" className="mb-2" />
                            <span className="text-wrap">
                                {getFuelTypeTemplate(partExchange) === 'ND' ? '-' : getFuelTypeTemplate(partExchange)}
                            </span>
                        </div>
                    )}
                    <div className="d-flex w-lg-50 w-xxl-25 mt-5 flex-column align-items-center text-center">
                        <img src="/media/icons/mileage.svg" alt="mileage" className="mb-2" />
                        <span>
                            {partExchange?.mileage ? (
                                <NumberFormat
                                    value={partExchange?.mileage}
                                    thousandSeparator=" "
                                    displayType="text"
                                    suffix=" km"
                                />
                            ) : (
                                '-'
                            )}
                        </span>
                    </div>
                    {getProductionYearTemplate(partExchange) && (
                        <div className="d-flex w-lg-50 w-xxl-25 mt-5 flex-column align-items-center text-center">
                            <img src="/media/icons/date.svg" alt="date" className="mb-2" />
                            <span>{getProductionYearTemplate(partExchange)}</span>
                        </div>
                    )}
                    {getGearsTypeTemplate(partExchange) !== '-' && getGearsTypeTemplate(partExchange) && (
                        <div className="d-flex w-lg-50 w-xxl-25 mt-5 flex-column align-items-center text-center">
                            <img src="/media/icons/gearbox.svg" alt="gearbox" className="mb-2" />
                            <span>{getGearsTypeTemplate(partExchange)}</span>
                        </div>
                    )}
                </div>
                {reprise ? (
                    <div className="d-flex flex-column align-items-center">
                        <div
                            className={classNames('d-flex w-100 p-2 text-center justify-content-center estimate', {
                                'flex-column': partExchange?.isVehicleModified,
                            })}
                        >
                            <span>
                                {Intl.formatMessage({
                                    id: `VEHICLE_INFO.VIEW.LIFE.DETAIL.RECOVERY.ESTIMATE${
                                        partExchange?.isVehicleModified ? '_INITIAL' : ''
                                    }`,
                                })}
                            </span>
                            <strong className="ml-2">
                                {partExchange?.estimatedPrice ? (
                                    <Price value={partExchange?.estimatedPrice} decimalSeparator="." />
                                ) : (
                                    '-'
                                )}
                            </strong>
                            {partExchange?.isVehicleModified && (
                                <span className="estimate__modified text-danger">
                                    <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.MODIFIED" />
                                </span>
                            )}
                        </div>
                        {partExchange?.partExchangeReference && (
                            <div className="text-center p-4 button-container w-100">
                                <span className="vehicle-recovery">
                                    <FormattedHTMLMessage id="VEHICLE_INFO.VIEW.LIFE.DETAIL.RECOVERY.WATCH_DETAIL" />
                                </span>
                                <br />
                                <Button
                                    variant="primary"
                                    className="mt-3"
                                    onClick={() =>
                                        dispatch(
                                            actions.modalChange('afr_part_exchange_detail', {
                                                partExchange,
                                            }),
                                        )
                                    }
                                >
                                    <i className="la la-mouse-pointer" />
                                    <FormattedMessage id="VEHICLE_INFO.VIEW.LIFE.DETAIL.RECOVERY.RECOVERY_DETAIL" />
                                </Button>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="d-flex flex-column align-items-center">
                        {event && event?.fullWeb && (
                            <div className="d-flex w-100 p-2 text-center justify-content-center estimate">
                                <span>
                                    {Intl.formatMessage({ id: 'VEHICLE_INFO.VIEW.LIFE.DETAIL.RECOVERY.ESTIMATE' })}
                                </span>
                                <strong className="ml-2">
                                    {event?.fullWeb.orderwebPrice ? (
                                        <Price value={event?.fullWeb.orderwebPrice} decimalSeparator="." />
                                    ) : (
                                        '-'
                                    )}
                                </strong>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PartExchangeInfo;
