import classNames from 'classnames';
import dayjs from 'dayjs';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import Footer from './Footer';
import { DATE, TAB_DATE, TAB_HOUR } from './Helpers/DatePickerHelper';
import HourGroup from './HourGroup';
import Tabs from './Tabs';

const DateTimePicker = (props) => {
    const {
        value,
        canClear,
        inline,
        timeOnly,
        showTime,
        icon,
        className,
        dateFormat,
        stepMinute,
        stepHour,
        hourTitle,
        hourSubTitle,
        minDate,
        minDateError,
        maxDate,
        maxDateError,
        isBirthdayDate,
        onChange,
        placeholder,
    } = props;
    const [tab, setTab] = useState(TAB_DATE);
    const overlayRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
        let text = '';

        if (Array.isArray(value)) {
            text = value
                .filter((date) => dayjs(date).isValid())
                .map((date) => dayjs(date).format(dateFormat))
                .join(' - ');
        } else if (dayjs(value).isValid()) {
            text = dayjs(value).format(dateFormat);
        }

        inputRef.current.value = text;
    }, [value]);

    const applyStep = (newValue, setted) => {
        let newDate = dayjs(newValue);
        const actualDate = dayjs(value);
        const today = dayjs();

        if (!newDate.isValid()) {
            return null;
        }

        if (setted === DATE) {
            if (actualDate.isValid()) {
                newDate = newDate.set('hour', actualDate.hour());
                newDate = newDate.set('minute', actualDate.minute());
            } else if (stepHour) {
                newDate = newDate.set('hour', (Math.round(today.hour() / stepHour) * stepHour) % 60);
            } else if (stepMinute) {
                newDate = newDate.set('minute', (Math.round(today.minute() / stepMinute) * stepMinute) % 60);
            }
        }

        newDate = newDate.set('second', 0);

        return newDate.toDate();
    };

    const isAllowed = (newValue) => {
        let result = {
            is_allowed: true,
            error: null,
        };

        if (minDate) {
            if (dayjs(newValue).isBefore(minDate, 'minute')) {
                result = {
                    is_allowed: false,
                    error: minDateError,
                };
            }
        }

        if (maxDate) {
            if (dayjs(newValue).isAfter(maxDate)) {
                result = {
                    is_allowed: false,
                    error: maxDateError,
                };
            }
        }

        return result;
    };

    const setDate = (newValue, setted = DATE) => {
        if (typeof onChange === 'function') {
            if (Array.isArray(newValue)) {
                onChange({
                    value: newValue.map((val) => applyStep(val, setted)),
                });
            } else {
                onChange({
                    value: dayjs(applyStep(isAllowed(newValue)?.is_allowed ? newValue : value, setted)).toDate(),
                });
            }
        }
    };

    const setVisible = (visible, event) => {
        const overlayElement = overlayRef.current.getElement();
        if (visible) {
            setTab(timeOnly ? TAB_HOUR : TAB_DATE);
            overlayRef.current.show(event);
            if (overlayElement) {
                overlayElement.style.display = 'block';
            }
        } else {
            overlayRef.current.hide();
            if (overlayElement) {
                overlayElement.style.display = 'none';
            }
        }
    };

    const calendarProps = { ...props };

    [
        'showIcon',
        'showButtonBar',
        'value',
        'onChange',
        'hourFormat',
        'canClear',
        'hourSubTitle',
        'hourTitle',
        'minDateError',
        'maxDateError',
    ].forEach((tag) => {
        if (calendarProps[tag]) {
            delete calendarProps[tag];
        }
    });

    if (inline) {
        return <Calendar {...calendarProps} />;
    }

    return (
        <div>
            <InputGroup className="mb-2" onClick={(e) => setVisible(true, e)}>
                <Form.Control
                    type="text"
                    className={className}
                    ref={inputRef}
                    readOnly
                    placeholder={placeholder}
                    onFocus={(e) => setVisible(true, e)}
                />
                <InputGroup.Append>
                    <InputGroup.Text>
                        {value && canClear ? (
                            <i
                                className="cursor-pointer p-datepicker-overlay__clear la la-times"
                                aria-hidden="true"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onChange({
                                        value: null,
                                    });
                                }}
                            />
                        ) : (
                            <i
                                className={classNames('cursor-pointer text-primary', {
                                    [icon]: icon,
                                    'far fa-calendar-alt': !icon,
                                })}
                            />
                        )}
                    </InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
            <OverlayPanel ref={overlayRef} className="p-datepicker-overlay">
                {showTime && !timeOnly && <Tabs setTab={setTab} tab={tab} />}
                <div className="p-datepicker-group-container">
                    {tab === TAB_DATE && (
                        <div className="p-datepicker-group p-datepicker-group--date">
                            <Calendar
                                {...calendarProps}
                                inline
                                value={value}
                                onChange={(e) => setDate(e.value)}
                                onVisibleChange={(e) => setVisible(e.visible, e)}
                                onHide={(e) => setVisible(false, e)}
                            />
                        </div>
                    )}
                    {tab === TAB_HOUR && (
                        <div className="p-datepicker-group p-datepicker-group--hour">
                            <HourGroup
                                date={value}
                                stepMinute={stepMinute}
                                stepHour={stepHour}
                                {...(hourSubTitle && { subtitle: hourSubTitle })}
                                {...(hourTitle && { title: hourTitle })}
                                setDate={setDate}
                                isAllowed={isAllowed}
                            />
                        </div>
                    )}
                </div>
                {!props.disabled && (
                    <Footer
                        setDate={setDate}
                        size={{ margin: '0 auto' }}
                        tab={tab}
                        setVisible={setVisible}
                        isBirthdayDate={isBirthdayDate !== undefined ? isBirthdayDate : true}
                    />
                )}
            </OverlayPanel>
        </div>
    );
};

DateTimePicker.defaultProps = {
    canClear: true,
    hourSubTitle: null,
    hourTitle: null,
    selectionMode: 'single',
    minDateError: 'minDate',
    maxDateError: 'maxDate',
};

export default DateTimePicker;
