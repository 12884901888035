import clsx from 'clsx';
import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import KTUtil from '@src/_metronic/_assets/js/util';

import useFilters from '@app/hooks/useFilters';

import { CustomerEvent } from '@app/crud/customers/customer.type';

import { useAppSelector } from '@app/hooks';
import { getCustomerEventSource, getTypeSlug, isEventSameAsRow } from '@app/pages/customers/Helpers/Events';
import CustomerLifeContext from '@app/pages/customers/Partials/customer-life/CustomerLifeContext';
import EventDetail from '@app/pages/customers/Partials/customer-life/EventDetail';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import Permission from '@app/partials/content/Permission';

import useEventNameTemplate from './LifeDateTableRender/BodyTemplate/EventName';
import useProductTemplate from './LifeDateTableRender/BodyTemplate/Product';
import usePriceTemplate from './LifeDateTableRender/PriceTemplate/Price';
import useReferentTemplate from './LifeDateTableRender/ReferentTemplate/Referent';
import useSourceTemplate from './LifeDateTableRender/SourceTemplate/Source';
import useStatusTemplate from './LifeDateTableRender/StatusTemplate/Status';

interface Props {
    events: CustomerEvent[];
    totalRecords: number;
    activeTab: string;
    tab: string;
}
const LifeDataTable = ({ events, totalRecords, activeTab, tab }: Props) => {
    const Intl = useIntl();
    const { data: customer, loading } = useAppSelector((state) => state.customer.customer);
    const { expandedRows, setExpandedRows, isExpandedRow } = useContext(CustomerLifeContext);
    const { start, filters, sortField, sortOrder } = useFilters({
        name: 'customers_search',
    });
    const dateBodyTemplate = (event: CustomerEvent) => (
        <div className="d-flex align-items-center">
            <span
                className={clsx({
                    'text-gray-500': !event.firstEventOfTheDay,
                })}
            >
                {dayjs(event?.eventDate).format('DD/MM/YYYY') || '-'}
            </span>
        </div>
    );

    const eventBodyTemplate = (event: CustomerEvent) => {
        const eventNameTemplate = useEventNameTemplate();
        const produitTemplate = useProductTemplate();

        const eventNameRender = eventNameTemplate(event);
        const produitRender = produitTemplate(event);

        return (
            <>
                <div className="leadType" />
                {KTUtil.isMobileDevice() && (
                    <div className="mb-1">
                        <FormattedHTMLMessage
                            id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.LEAD.CREATED"
                            values={{ date: dayjs(event?.eventDate).format('DD/MM/YYYY') || '-' }}
                        />
                    </div>
                )}
                <div className="d-flex align-items-center mb-1">
                    <span>{eventNameRender}</span>
                    {event?.leadParentId && <i className="ml-2 las la-xl la-link text-muted" />}
                </div>
                <div>{produitRender}</div>
            </>
        );
    };

    const sourceBodyTemplate = (event: CustomerEvent) => {
        const source = getCustomerEventSource(event) as { name: string; slug: string; originName: string };
        const { getSourceTemplate, getSourceClassName } = useSourceTemplate();
        const sourceRender = getSourceTemplate(event);
        return (
            <>
                <div className={`lead-history__source--${getSourceClassName(event)} font-weight-bold`}>
                    {sourceRender}
                </div>
                {source?.originName && (
                    <Permission permissions={['LEAD_SHOW_SOURCE']}>
                        <span className="font-italic">{source?.originName}</span>
                    </Permission>
                )}
            </>
        );
    };

    const dealershipBodyTemplate = (event: CustomerEvent) => event?.leadDealershipName || '';

    const priceBodyTemplate = (event: CustomerEvent) => {
        const priceTemplate = usePriceTemplate();
        const priceRender = priceTemplate(event);

        return priceRender ? (
            <NumberFormat
                value={priceRender}
                decimalSeparator="."
                thousandSeparator=" "
                decimalScale={2}
                className="text-nowrap"
                allowedDecimalSeparators={['.', ',']}
                suffix=" €"
                displayType="text"
            />
        ) : (
            '-'
        );
    };

    const referentBodyTemplate = (event: CustomerEvent) => {
        const referentTemplate = useReferentTemplate();
        const referentRender = referentTemplate(event);
        return referentRender;
    };

    const statusBodyTemplate = (event: CustomerEvent) => {
        const statusTemplate = useStatusTemplate();
        const statusRender = statusTemplate(event);
        return statusRender;
    };

    const sourceStatusBodyTemplate = (event: CustomerEvent) => (
        <>
            {sourceBodyTemplate(event)}
            {statusBodyTemplate(event)}
        </>
    );

    const toggleExpandRow = (data: CustomerEvent) => {
        if (isExpandedRow(data)) {
            setExpandedRows([...expandedRows.filter((expandedRow) => !isEventSameAsRow(data, expandedRow))]);
        } else if (!!data?.leadId || !!data?.eventId) {
            setExpandedRows([data]);
        }
    };

    const actionsBodyTemplate = (event: CustomerEvent) => (
        <div className="text-center">
            <Button
                className="p-2 px-3 m-lg-auto m-0"
                variant="primary"
                id={`event-${tab}-${event?.leadId || event?.eventId}`}
                onClick={() => toggleExpandRow(event)}
            >
                <i className={`la ${isExpandedRow(event) ? 'la-minus' : 'la-plus'} pr-0`} />
            </Button>
        </div>
    );

    const rowExpansionTemplate = (data: CustomerEvent) => (
        <EventDetail data={data} activeTab={activeTab} tab={tab} customer={customer} />
    );

    const expandedRowClass = (data: CustomerEvent) => ({
        'expanded-row': isExpandedRow(data),
        [`expanded-row__origin-bg--${getTypeSlug(
            data?.processType,
            data?.leadTypeSlug || data?.eventTypeSlug,
            data?.srcApplicationName,
        )}`]: isExpandedRow(data),
    });

    const cols = [
        KTUtil.isDesktopDevice() && {
            field: 'date',
            bodyClassName: 'd-none d-lg-table-cell position-relative',
            headerClassName: 'd-none d-lg-table-cell',
            header: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.TABLE.DATE' }),
            body: dateBodyTemplate,
            style: { width: '7%', verticalAlign: 'top' },
        },
        {
            field: 'event',
            header: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.TABLE.EVENT' }),
            body: eventBodyTemplate,
            style: KTUtil.isDesktopDevice()
                ? { verticalAlign: 'top', width: '30%' }
                : { verticalAlign: 'top', width: '15%' },
        },
        KTUtil.isMobileDevice() && {
            field: 'source',
            header: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.TABLE.SOURCE_STATUS' }),
            body: sourceStatusBodyTemplate,
            style: { width: '13%', verticalAlign: 'top' },
        },
        KTUtil.isDesktopDevice() && {
            field: 'source',
            header: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.TABLE.SOURCE' }),
            body: sourceBodyTemplate,
            style: { verticalAlign: 'top' },
        },
        KTUtil.isDesktopDevice() && {
            field: 'dealership',
            bodyClassName: 'd-none d-lg-table-cell',
            headerClassName: 'd-none d-lg-table-cell',
            header: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.TABLE.DEALERSHIP' }),
            body: dealershipBodyTemplate,
            style: { verticalAlign: 'top' },
        },
        KTUtil.isDesktopDevice() && {
            field: 'referent',
            bodyClassName: 'd-none d-lg-table-cell',
            headerClassName: 'd-none d-lg-table-cell',
            header: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.TABLE.REFERENT' }),
            body: referentBodyTemplate,
            style: { verticalAlign: 'top' },
        },
        KTUtil.isDesktopDevice() && {
            field: 'status',
            bodyClassName: 'd-none d-lg-table-cell',
            headerClassName: 'd-none d-lg-table-cell',
            header: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.TABLE.STATUS' }),
            body: statusBodyTemplate,
            style: { width: '10%', verticalAlign: 'top' },
        },
        KTUtil.isDesktopDevice() && {
            field: 'price',
            bodyClassName: 'd-none d-lg-table-cell',
            headerClassName: 'd-none d-lg-table-cell',
            header: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.TABLE.PRICE' }),
            body: priceBodyTemplate,
            style: { width: '6%', verticalAlign: 'top' },
        },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.TABLE.ACTION' }),
            body: actionsBodyTemplate,
            expander: true,
            style: KTUtil.isDesktopDevice()
                ? { width: '6%', textAlign: 'center' as const }
                : { width: '9%', padding: '1rem 0.5rem', textAlign: 'center' as const },
        },
    ];

    const dynamicColumns = cols.map((col) => col && <Column key={col.field} {...col} />);

    return (
        <div>
            <FiltersProvider value={filters}>
                {/* @ts-ignore */}
                <HoCDataTable
                    value={events}
                    dataKey="eventId"
                    totalRecords={totalRecords}
                    rowGroupMode="subheader"
                    groupRowsBy="eventDate"
                    rowGroupHeaderTemplate={() => <span />}
                    rowGroupFooterTemplate={() => ''}
                    lazy
                    start={start}
                    first={start}
                    loading={loading}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    removableSort
                    emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                    rowExpansionTemplate={rowExpansionTemplate}
                    expandedRows={expandedRows}
                    rowClassName={expandedRowClass}
                >
                    {dynamicColumns}
                </HoCDataTable>
            </FiltersProvider>
        </div>
    );
};

export default LifeDataTable;
