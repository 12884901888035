import React, { Suspense } from 'react';
import { Redirect, Switch, withRouter } from 'react-router-dom';

import { LayoutSplashScreen } from '@src/_metronic';

import { getUserData } from '@app/helpers/UserHelper';

import ROUTES from '@app/router/Routes';

import AuthRoute from '@app/partials/content/AuthRoute';

const HomePage = () => {
    const user = getUserData();

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {/* This is a specific use case for 'Accueil' role only
                In the future, autouser will provide us that logic instead */}
                {user.user.role.slug === 'accueil' ? (
                    <Redirect exact from="/" to="/customers" />
                ) : (
                    <Redirect exact from="/" to="/dashboard" />
                )}
                {Object.values(ROUTES).map((route) => {
                    const path = typeof route.PATH === 'object' ? Object.values(route.PATH) : [route.PATH];

                    return (
                        <AuthRoute
                            key={path.join('/')}
                            {...{
                                ...route,
                                path,
                            }}
                        />
                    );
                })}
                <Redirect to="/error" />
            </Switch>
        </Suspense>
    );
};

export default withRouter(HomePage);
