export const enum StatusContractSlug {
    TO_SIGN = 'ASIGNER',
    DRAFT = 'PROPOS',
    DELETED = 'SUPPR',
    VALID = 'VALIDE',
    END_VALID = 'FINVALID',
}

export const enum LawCode {
    ELEC = 'SignatureElectronique',
    MANUAL = 'SignaturePapier',
    BOTH = 'SignaturePapier, SignatureElectronique',
}

export const enum ContractType {
    CLASSIQUE = 'CLASSIQUE',
    ELECTRIQUE = 'ELECTRIQUE',
}

export type LawCodeType = LawCode.ELEC | LawCode.MANUAL | LawCode.BOTH;

export interface Contract {
    id: string;
    numero: string;
    statut: StatusContractSlug | null;
    idVendeur: string;
    idSecretaire: string;
    idSouscripteur: string;
    dateSouscription: string;
    dateDebutValidite: string;
    dateFinValidite: string;
    dateImpression: string;
    modeFinancementVehicule: string;
    dateCarteGrise: string;
    montantAchatVehicule: number;
    modePaiement: string;
    prixVenteHt: number;
    prixVenteTtc: number;
    dateAutorisationPrelevement: string;
    dateBonCommande: string;
    referenceUniqueMandat: string;
    produit: Product;
    concession?: Concession;
    vehicule: Vehicle;
    rib: Rib;
    souscripteur: Client;
    beneficiaire: Client;
    optins: string;
    nomFichier: string;
    dateCreation: string;
    dateModification: string;
    validitesPrecedentes: unknown[];
    dureeSouscription: number;
    idAgent?: unknown;
    offreFamille?: unknown;
    offreCollaborateur?: unknown;
    estCompletude: boolean;
    langue: string;
    secretaire: Employee | null;
    vendeur: Employee | null;
    estAncienneSolutionRecharge: boolean;
    typeSolutionRecharge: string;
    parcoursClient: string;
    dateSolution: string;
    sourceCreation: 'SECURYWEB' | 'AUTOMANAGER';
    typeContrat: ContractType.CLASSIQUE | ContractType.ELECTRIQUE;
}

export interface Client {
    idAutomanager: number | null;
    id: string;
    code?: string;
    nom: string;
    prenom: string;
    civilite: string;
    adresse1?: string;
    adresse2?: unknown;
    adresse3: string;
    adresse4?: unknown;
    ville: Cities;
    complementVille?: unknown;
    telephoneFixe: string;
    telephonePortable: string;
    email: string;
    dateNaissance: string;
    statutMarital: string;
    categorieProfessionnelle: string;
    typeLogement: string;
    modeLogement: string;
}

interface Rib {
    id: string;
    nomTitulaireCompte: string;
    adresseTitulaireCompte: string;
    codePostalTitulaireCompte: string;
    villeTitulaireCompte: string;
    iban: string;
    bic: string;
}

interface Modele {
    id: string;
    libelle: string;
    marque: Groupe;
}

interface Concession {
    code: string;
    denomination: string;
    raisonSociale: string;
    groupe: Groupe;
    pole: Groupe;
    telephone: string;
    ville: Cities;
    dateDesactivation?: unknown;
    employes: Employee[];
    numeroOrias: string;
    capital: string;
    rcsVilleImmatriculation: string;
    rcsNumeroImmatriculation: string;
    email: string;
}

interface Employee {
    id: string;
    nom: string;
    prenom: string;
    fonction: string;
    email: string;
    telephonePortable: string;
    estArchive: boolean;
    concessions: string[];
}

export interface Cities {
    id: string;
    codePostal: string;
    localite: string;
    pays: string;
}

interface Groupe {
    code: string;
    libelle: string;
}

export interface Vehicle {
    id: string;
    codeVin: string;
    immatriculation: string;
    typeCnit: string;
    genre: string;
    datePremiereMiseEnCirculation: string;
    modele: Modele;
    dateCarteGrise: string;
}

export interface Product {
    code: string;
    libelle: string;
    dateDebutValidite: string;
    dateFinValidite?: unknown;
    dureeSouscription: string;
    famille: string;
    prestations: Prestation[];
    documents: unknown[];
    prix: unknown[];
    codeProduitRenouv: string;
    dureeRenouvellement: string;
    estProduitCoyote: boolean;
}

export interface Optins {
    idContrat: string;
    labelAutoriseGroupe: string;
    autoriseGroupe: boolean;
    labelAutorisePartenaires: string;
    autorisePartenaires: boolean;
}

interface Prestation {
    code: string;
    libelle: string;
    type: string;
}

interface UserSubset {
    id?: number;
    firstname?: string;
    lastname?: string;
}

export interface DealershipKnow {
    refDealershipId: string;
    refDealershipName: string;
}

export interface MappedCustomer {
    civility?: 'mr' | 'mme';
    firstname?: string;
    lastname?: string;
    birthday?: string;
    companyName?: string;
    siren?: string;
    additionalAddress?: string;
    address?: string;
    zipcode?: string;
    city?: string;
    isClient?: boolean;
    phone?: string;
    mobile?: string;
    isPro?: boolean;
    proPhone?: string;
    companyPhone?: string;
    dealershipKnow?: DealershipKnow[];
    user?: UserSubset;
}

export interface SecurywebContractsResponse {
    contrats: Contract[];
    nbContrats: number;
    totalContrats: number;
}
