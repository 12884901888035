import dayjs from 'dayjs';

import store from '../store/store';

export const getUserData = () => store.getState().auth;

/**
 * Check if user is associate to a dealership
 *
 * @param {string} dealerId
 * @returns {undefined|*}
 */
export const userIsAssociateToDealership = (dealerId: string | false) => {
    const { userData } = getUserData();

    if (dealerId && userData?.dealerships) {
        return Object.keys(userData.dealerships).find((refDealershipId) => dealerId === refDealershipId);
    }

    return true;
};

/**
 * Get current dealership (default or first affected)
 * @returns {{name: *, id: (*|string|undefined)}}
 */
export const getCurrentDealership = () => {
    const { userData } = getUserData();

    const dealership = userData?.dealerships?.[userData?.default_dealership];
    const refDealershipIds = Object.keys(userData?.dealerships);
    const dealerships = Object.values(userData?.dealerships);

    return {
        ...dealership,
        id: dealership ? userData.default_dealership : refDealershipIds?.[0],
        name: dealership ? dealership?.title : dealerships?.[0]?.title,
    };
};

/**
 * Get dealership associated to a user by dealership code
 * @returns {{name: *, id: (*|string|undefined)}}
 */
export const getUserDealershipById = (dealershipId: string) => {
    const { userData } = getUserData();

    const dealership = userData?.dealerships?.[dealershipId];
    const refDealershipIds = Object.keys(userData?.dealerships);
    const dealerships = Object.values(userData?.dealerships);

    return {
        ...dealership,
        id: dealership ? dealershipId : refDealershipIds?.[0],
        name: dealership ? dealership?.title : dealerships?.[0]?.title,
    };
};

/**
 * Checks if a date is active or inactive based on comparison with the current date.
 * @param {string} receivedDate - The date to be checked (in string format).
 * @returns {boolean} - Returns true if the date is active, false if inactive.
 */
export const checkDateStatus = (receivedDate: string) => {
    const comparedDate = dayjs(receivedDate);
    const currentDate = dayjs();

    return currentDate.isBefore(comparedDate) || currentDate.isSame(comparedDate);
};
