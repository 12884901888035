import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Action as LeadAction, actions as leadActions } from '@app/store/lead/lead.store';
import { actions } from '@app/store/modal/modal.store';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import { RootState } from '@app/store/rootDuck';
import { addActionConsignReportController } from '@app/crud/customers/customer.crud';
import { refreshLeads } from '@app/store/customers/customers.thunk';

type ConsignReportControlledProps = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
};

const ConsignReportControlled = ({ showModal, setShowModal }: ConsignReportControlledProps) => {
    const dispatch = useDispatch();
    const Intl = useIntl();
    const { register, handleSubmit } = useForm();
    const [loading, setLoading] = useState(false);
    const { params } = useSelector((state: RootState) => state.modal);

    const onSubmit = async (data: any) => {
        setLoading(true);

        const leadAction: LeadAction = {
            action: 'consign_report_controlled',
            id: params?.leadId,
        };

        dispatch(leadActions.startAction(leadAction));

        addActionConsignReportController({
            lead: params?.leadId,
            comment: data.comment,
        })
            .then(async (response) => {
                setLoading(false);

                if (response?.result?.error) {
                    failed();
                    return;
                }

                dispatch(refreshLeads(params?.customerId, 'consign_report_controlled'));

                setShowModal(false);

                dispatch(
                    actions.modalChange('next_action', {
                        leadId: params?.leadId,
                    }),
                );

                dispatch(leadActions.stopAction(leadAction));
            })
            .catch(() => {
                setLoading(false);
                failed();
                dispatch(leadActions.stopAction(leadAction));
            });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const failed = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VISIT.ERROR' }),
        });
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon=<i className="la la-2x text-primary la-car" />
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CONSIGN.REPORT_TO_CONTROL.TITLE' })}
            body={
                <>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CONSIGN.RESULT' })}</Form.Label>
                        <Form.Control as="textarea" ref={register()} name="comment" rows={4} />
                    </Form.Group>
                </>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default ConsignReportControlled;
