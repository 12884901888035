import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import { createTchek, getTcheks, remindTchek } from '@app/crud/customers/tchek.crud';

import { useFetch } from '@app/hooks';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import TchekStatus from './tchek-status';

dayjs.extend(relativeTime);

export interface BlockProps {
    partExchange: {
        id: number;
        registration: string;
    };
}

const { REACT_APP_TCHEK_FAST_TRACK_URL } = process.env;

const TchekBtn = ({ partExchange }: BlockProps) => {
    const {
        fetch: fetchData,
        data,
        loading: loadingEventDetail,
    } = useFetch({
        fetchAction: getTcheks,
    });

    const [processing, setProcessing] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const Intl = useIntl();

    useEffect(() => {
        fetchData(partExchange.id);
    }, [partExchange]);

    const create = () => {
        if (processing) {
            return;
        }

        if (!partExchange.registration) {
            toast({
                variant: 'warning',
                message: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.NO_REGISTRATION' }),
            });
            return;
        }

        setProcessing(true);

        createTchek(partExchange.id)
            .then(() => {
                setProcessing(false);
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.CREATE_SUCCESS' }),
                });

                fetchData(partExchange.id);
            })
            .catch(() => {
                setProcessing(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.CREATE_ERROR' }),
                });
            });
    };

    const remind = () => {
        if (processing || !data || data.length === 0 || data[0].isDetectionFinished || !data[0].reminderTokenId) {
            return;
        }

        setProcessing(true);

        remindTchek(partExchange.id)
            .then(() => {
                setProcessing(false);
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.REMIND.SUCCESS' }),
                });

                fetchData(partExchange.id);
            })
            .catch(() => {
                setProcessing(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.REMIND.ERROR' }),
                });
            });
    };

    if (loadingEventDetail || processing) {
        return (
            <Button variant="outline-primary" className="d-flex align-items-center" disabled>
                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
                <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.LOADING" />
            </Button>
        );
    }
    if (!data) {
        return (
            <Button variant="outline-primary" className="d-flex align-items-center" disabled>
                <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.UNKNOWN" />
            </Button>
        );
    }
    if (data.length === 0) {
        return (
            <Button variant="outline-primary" className="d-flex align-items-center" onClick={() => create()}>
                <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.SEND" />
            </Button>
        );
    }

    const status = <TchekStatus status={data[0].tradeInStatus} updatedAt={data[0].tradeInStatusUpdatedAt} />;

    if (!data[0].isDetectionFinished) {
        if (data[0].reminderTokenId) {
            return (
                <>
                    <Button
                        variant="outline-primary"
                        className="d-flex align-items-center"
                        onClick={() => setShowConfirmModal(true)}
                    >
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.REMIND" />
                    </Button>
                    <ModalDefault
                        show={showConfirmModal}
                        hideModal={() => setShowConfirmModal(false)}
                        icon={<i className="text-primary las la-exclamation-triangle fa-2x" />}
                        title={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.REMIND' })}
                        body={
                            <>
                                <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.REMIND.TEXT" />
                                {data[0].nbReminds > 0 && (
                                    <>
                                        &nbsp;
                                        <FormattedHTMLMessage
                                            id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.REMIND.NB_REMINDS"
                                            values={{ nb: data[0].nbReminds }}
                                        />
                                    </>
                                )}
                                {data[0].lastRemind && (
                                    <>
                                        &nbsp;
                                        <FormattedHTMLMessage
                                            id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.REMIND.LAST_REMIND"
                                            values={{ last: dayjs(data[0].lastRemind).fromNow() }}
                                        />
                                    </>
                                )}
                                <>
                                    &nbsp;
                                    <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.REMIND.ASK" />
                                </>
                            </>
                        }
                        footer={
                            <>
                                <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                                    {Intl.formatMessage({
                                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.REMIND.CANCEL',
                                    })}
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        setShowConfirmModal(false);
                                        remind();
                                    }}
                                >
                                    {Intl.formatMessage({
                                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.REMIND.CONFIRM',
                                    })}
                                </Button>
                            </>
                        }
                    />
                    {status}
                </>
            );
        }

        return (
            <>
                <Button variant="outline-primary" className="d-flex align-items-center" disabled>
                    <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.SENT" />
                </Button>
                {status}
            </>
        );
    }

    return (
        <>
            <a
                href={`${REACT_APP_TCHEK_FAST_TRACK_URL.replace('{id}', data[0].tchekId)}`}
                className="btn btn-outline-primary"
                rel="noreferrer"
                target="_blank"
            >
                <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.READY" />
            </a>
            {status}
        </>
    );
};

export default TchekBtn;
