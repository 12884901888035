import { useCallback, useEffect, useRef, useState } from 'react';

import { CustomAxiosResponse } from '../helpers/AxiosHelper';

type useFetchProps<T, S, U> = {
    fetchAction: (params: T) => Promise<CustomAxiosResponse<S>>;
    resultInterceptor?: (response: CustomAxiosResponse<S>, previousResponse?: any, config?: any) => U | undefined[];
    autoFetch?: boolean;
    interceptResponse?: boolean;
    axios?: boolean;
};
const useFetch = <T, S, U = S>({
    fetchAction,
    // @ts-ignore
    resultInterceptor = (response) => response?.result || [],
    autoFetch = false,
    interceptResponse = false,
    axios = true,
}: useFetchProps<T, S, U>) => {
    const initData: unknown = [];
    const [data, setData] = useState<U>(initData as U);
    const previousData = useRef([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const updateData = useCallback(
        // @ts-ignore
        (newData) => {
            setData(newData);
            previousData.current = newData;
        },
        [setData],
    );

    const fetch = useCallback(
        (params?: T, config?: object) => {
            setLoading(true);

            return fetchAction(params)
                .then((response: CustomAxiosResponse<S>) => {
                    let currentData = {} as CustomAxiosResponse<S>;
                    setLoading(false);

                    if (!axios && response?.axios) {
                        delete response.axios;
                    }

                    if (response?.result?.error) {
                        setError(true);
                    } else {
                        currentData = response;
                        setError(false);
                    }

                    const data = resultInterceptor(currentData, previousData.current, {
                        ...params,
                        ...config,
                    });

                    updateData(data);

                    if (interceptResponse) {
                        return data;
                    } else {
                        return response;
                    }
                })
                .catch(() => {
                    setLoading(false);
                    setError(true);
                    // @ts-ignore
                    setData([]);

                    previousData.current = [];
                });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [fetchAction, updateData, setLoading, setError, setData],
    );

    useEffect(() => {
        if (autoFetch) {
            fetch(null, null);
        }
    }, []);

    return {
        fetch,
        data,
        loading,
        error,
        setData: updateData,
        setLoading,
    };
};

export default useFetch;
