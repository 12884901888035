import React, { useEffect, useRef, useState } from 'react';
import { Button, ListGroup, Overlay, Popover } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { getTchekUsers, TchekUser } from '@app/crud/customers/tchek.crud';

import { useFetch } from '@app/hooks';

interface TchekUserSelectorProps {
    onSelected: (user: TchekUser) => void;
}

const TchekUserSelector = ({ onSelected }: TchekUserSelectorProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);

    const {
        fetch: fetchTchekUsers,
        data: tchekUsers,
        loading: loadingTchekUsers,
    } = useFetch({
        fetchAction: getTchekUsers,
    });

    useEffect(() => {
        if (tchekUsers.length > 0) {
            setIsOpen(true);
        }
    }, [tchekUsers]);

    const handleUser = (user: TchekUser) => {
        setIsOpen(false);
        onSelected(user);
    };

    return (
        <>
            <Button ref={ref} onClick={() => fetchTchekUsers()} variant="outline-primary" disabled={loadingTchekUsers}>
                {loadingTchekUsers ? <i className="fa fa-spinner fa-spin p-0" /> : <i className="fa fa-search p-0" />}
            </Button>
            <Overlay show={isOpen} target={ref.current}>
                <Popover id="fetchTchekUsers">
                    <Popover.Title as="h3">
                        <FormattedMessage id="AUTOUSER.USER.MODAL.MANAGE.PARTNER.TCHEK_HELPER" />
                    </Popover.Title>
                    <Popover.Content className="p-0">
                        <ListGroup variant="flush">
                            {tchekUsers.map((user) => (
                                <ListGroup.Item key={`tchek_user_${user.id}`} action onClick={() => handleUser(user)}>
                                    {user.username}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    );
};

export default TchekUserSelector;
